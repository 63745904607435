import React, { useEffect, useState } from "react";
import AnimatedCursor from "react-animated-cursor";
import MobileMenu from "./MobileMenu";
import $ from "jquery";
import Footer from "./Footer";
import { PageHeader } from "./PageHeader";
import { Link } from "react-router-dom";

const BlogBox = (props) => {
  return (
    <div className="col-xl-4 col-md-6 wow fadeInUp" data-wow-delay="400ms">
      <div className="blog-three__item">
        <div className="blog-three__image">
          <img src={props.img_url} alt={props.link_tag} />
          <Link to={`/blog/${props.link_tag}`}></Link>
        </div>
        {/* /.blog-image */}
        <div className="blog-three__content">
          <div className="blog-three__top-meta">
            <div className="blog-three__cats">
              <Link to={`/blog/${props.link_tag}`}>New</Link>
            </div>
            {/* /.blog-cats */}
            <div className="blog-three__date">{props.date}</div>
            {/* /.blog-date */}
          </div>
          <h3 className="blog-three__title">
            <Link to={`/blog/${props.link_tag}`}>{props.title}</Link>
          </h3>
          {/* /.blog-title */}
          <div className="blog-three__meta">
            <div className="blog-three__meta__author">
              <img src="assets/images/nilay-doshi.png" alt="eduact" />
              <Link to="/about">Mr. Nilay Doshi</Link>
              Founder
            </div>
            <Link to={`/blog/${props.link_tag}`} className="blog-three__rm">
              <span className="icon-arrow" />
            </Link>
            {/* /.read-more-btn */}
          </div>
          {/* /.blog-meta */}
        </div>
        {/* /.blog-content */}
      </div>
      {/* /.blog-card-one */}
    </div>
  );
};

export default function LatestBlog(props) {
  const [mobileMenu, setMMV] = useState(false);

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const formData = new FormData();
    formData.append("API_KEY", "RecallX84014");
    formData.append("op", "blogList");

    fetch("https://api.recallx.in/blog.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        
        if (data.error_code === 0) {
          setData(data.blog);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <section className="blog-three">
      <div className="container">
        <div
          className="section-title text-center wow fadeInUp"
          data-wow-delay="100ms"
        >
          <h5 className="section-title__tagline">
            Latest Blog
            <svg
              className="arrow-svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 55 13"
            >
              <g clipPath="url(#clip0_324_36194)">
                <path d="M10.5406 6.49995L0.700562 12.1799V8.56995L4.29056 6.49995L0.700562 4.42995V0.819946L10.5406 6.49995Z" />
                <path d="M25.1706 6.49995L15.3306 12.1799V8.56995L18.9206 6.49995L15.3306 4.42995V0.819946L25.1706 6.49995Z" />
                <path d="M39.7906 6.49995L29.9506 12.1799V8.56995L33.5406 6.49995L29.9506 4.42995V0.819946L39.7906 6.49995Z" />
                <path d="M54.4206 6.49995L44.5806 12.1799V8.56995L48.1706 6.49995L44.5806 4.42995V0.819946L54.4206 6.49995Z" />
              </g>
            </svg>
          </h5>
          <h2 className="section-title__title">Latest Updates Articles</h2>
        </div>

        {/* section-title */}
        <div className="row">
          {data.map((data, index) => {
            return (
              <BlogBox
                title={data.title}
                img_url={data.img}
                link_tag={data.link_tag}
                date={data.date}
                key={data.id}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}
