import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export const Testimonial = () => {
  const boxStyle = {
    padding: 20,
    borderRadius: 20,
    marginBottom: 100,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    elevation: 14,
  };

  const data = [
    {
      id: 1,
      img: "https://api.recallx.in/data/jayanthi manglagiri.jpeg",
      name: "Jayanthi Mangalagiri",
      text: "Nilay Doshi’s RecallX course has been instrumental in helping me prepare for my exams. His techniques are simple yet extremely effective for retaining even the most complicated topics.",
      location: "Visakhapatnam",
    },
    {
      id: 2,
      img: "https://api.recallx.in/data/shivangi mudgal.jpeg",
      name: "Shivangi Mudgal",
      text: "Nilay Doshi has completely revolutionized how I approach studying. His RecallX program is perfect for tackling difficult subjects and remembering them effortlessly.",
      location: "Indore",
    },
    {
      id: 3,
      img: "https://api.recallx.in/data/megha parikh.jpeg",
      name: "Megha Parikh",
      text: "My son took the RecallX Speed Reading Course, and the results have been incredible! He now reads much faster and retains information better. The daily sessions were engaging and made learning enjoyable. Highly recommend it!",
      location: "Vadodara",
    },
    {
      id: 4,
      img: "https://api.recallx.in/data/vidhi hemnani.jpeg",
      name: "Vidhi Hemnani",
      text: "I can’t believe the results after completing RecallX. My reading speed improved dramatically, and the note-making techniques have been incredibly effective. Nilay Doshi’s course is packed with practical tips, and I highly recommend it to everyone!",
      location: "Lucknow",
    },
    {
      id: 5,
      img: "https://api.recallx.in/data/parita pathak.jpeg",
      name: "Parita Pathak",
      text: "The RecallX masterclass is a game-changer for anyone who struggles with retaining information. The course is thorough and designed for all ages, making reading complex topics so much easier.",
      location: "Ahmedabad",
    },
    {
      id: 6,
      img: "https://api.recallx.in/data/mamta joshi.jpeg",
      name: "Mamta Joshi",
      text: "RecallX is a beautifully designed program that helped me increase my reading speed and comprehension significantly. The techniques are perfect for anyone wanting to handle lengthy and difficult topics.",
      location: "Ahmedabad",
    },
    {
      id: 7,
      img: "https://api.recallx.in/data/kosha jariwala.jpeg",
      name: "Kosha Jariwala",
      text: "I cannot thank Nilay Doshi enough for this amazing course! His RecallX program has helped me improve my focus, speed, and memory retention like never before.",
      location: "Vadodara",
    },
    {
      id: 8,
      img: "https://api.recallx.in/data/grishma shah.jpeg",
      name: "Grishma Shah",
      text: "RecallX is perfect for all ages. It’s beautifully structured, easy to follow, and highly effective. I now manage complex topics with ease and can retain much more information.",
      location: "Kochi",
    },
    {
      id: 9,
      img: "https://api.recallx.in/data/hiral doshi.jpeg",
      name: "Hiral Doshi",
      text: "The RecallX Speed Reading Course exceeded my expectations! My son now reads three times faster and remembers more of what he reads. The instructors were fantastic and very supportive.",
      location: "Pune",
    },
    {
      id: 10,
      img: "https://api.recallx.in/data/avinash bhalerao.jpeg",
      name: "Avinash Bhalerao",
      text: "After completing RecallX, I’ve seen a huge difference in my reading speed and ability to recall information effortlessly. Nilay Doshi’s techniques for note-taking are unique and highly effective. This course is a must for anyone looking to improve their learning skills.",
      location: "Surat",
    },
    {
      id: 11,
      img: "https://api.recallx.in/data/krishna parikh.jpeg",
      name: "Krishna Parikh",
      text: "Nilay Doshi’s RecallX course has completely changed the way I approach learning. His techniques are so easy to implement and have helped me remember even the most complex topics with ease.",
      location: "Jaipur",
    },
    {
      id: 12,
      img: "https://api.recallx.in/data/harsha ramwani.jpeg",
      name: "Harsha Ramwnai",
      text: "Nilay Doshi’s RecallX program has transformed my study habits. His methods are simple yet powerful, and now I can tackle difficult topics without stress.",
      location: "Varanasi",
    },
    {
      id: 13,
      img: "https://api.recallx.in/data/mamta purabia.jpeg",
      name: "Mamta Purabia",
      text: "RecallX is a fantastic course! It’s helped me increase my reading speed and retain information without the need for notes. This course is a must for anyone serious about learning.",
      location: "Kolkata",
    },
    {
      id: 14,
      img: "https://api.recallx.in/data/sejal gandhi.jpeg",
      name: "Sejal Gandhi",
      text: "RecallX is a life-saver! I’ve seen a drastic improvement in my reading speed, and I can now retain information without cramming. This course is a must for anyone looking to study smarter.",
      location: "Mumbai",
    },
    {
      id: 15,
      img: "https://api.recallx.in/data/priya gupta.jpeg",
      name: "Priya Gupta",
      text: "RecallX is hands down the best course I’ve taken for improving speed reading and memory skills. Nilay Doshi’s insights and teaching techniques are invaluable. I’ve seen a huge transformation, and I’m so glad I took this course!",
      location: "Chennai",
    },
    {
      id: 16,
      img: "https://api.recallx.in/data/jinal kachhiya.jpeg",
      name: "Jinal Kachhiya",
      text: "The RecallX course was exactly what I needed to enhance my reading and memory skills. Nilay Doshi has done a fantastic job with this pre-recorded program. It’s easy to follow, and I’ve experienced a big transformation in how I approach learning.",
      location: "New Delhi",
    },
    {
      id: 17,
      img: "https://api.recallx.in/data/drashti shah.jpeg",
      name: "Drashti Shah",
      text: "Nilay Doshi’s teaching style in RecallX is phenomenal. He breaks down complex ideas and makes them easy to understand, helping me remember lengthy topics with minimal effort.",
      location: "Ahmedabad",
    },
    {
      id: 18,
      img: "https://api.recallx.in/data/akansha sarolkar.jpeg",
      name: "Akasha Sarolkar",
      text: "RecallX helped me immensely with improving my reading speed and memory. The course, led by Nilay Doshi, is structured perfectly to help retain information effortlessly. My productivity has skyrocketed, and I couldn’t be more thankful! Highly recommend it.",
      location: "Indore",
    },
    {
      id: 19,
      img: "https://api.recallx.in/data/kinjal roa.jpeg",
      name: "Kinjal Rao",
      text: "RecallX! Enrolling in the RecallX speed reading program has been life-changing! I saw a huge transformation in my reading speed and the ability to recall information easily. The note-taking vs. note-making techniques were game-changers. Nilay Doshi is an incredible coach!",
      location: "Hyderabad",
    },
    {
      id: 20,
      img: "https://api.recallx.in/data/khushbu mehta.jpeg",
      name: "Khushbu Mehta",
      text: "Excellent Coaching by Nilay Doshi RecallX taught me how to read faster and retain information with ease. Nilay Doshi’s coaching style and the course structure are excellent. The lessons on note-taking vs. note-making have been incredibly helpful. A must-try for anyone looking to improve their skills.",
      location: "Gandhinagar",
    },
    {
      id: 21,
      img: "https://api.recallx.in/data/nikita kapadiya.jpeg",
      name: "Nikita Kapadiya",
      text: "Very impressed with RecallX! My child learned to read faster and retain information much better. The structured format made it easy to follow, and the daily drills were beneficial.",
      location: "Surat",
    },
    {
      id: 22,
      img: "https://api.recallx.in/data/gayatri patel.jpeg",
      name: "Gayatri Patel",
      text: "I highly recommend RecallX for any parent looking to boost their child's reading skills. My daughter has become more efficient in her studies, thanks to the practical techniques she learned.",
      location: "Anand",
    },
    {
      id: 23,
      img: "https://api.recallx.in/data/konkatti padmavati.jpeg",
      name: "Kontakti Padmavati",
      text: "I enrolled my daughter in the RecallX course, and I can’t believe the transformation! She has improved her reading speed and comprehension significantly. The structured approach really works!",
      location: "Chennai",
    },
    {
      id: 24,
      img: "https://api.recallx.in/data/Shyam Babariya.jpeg",
      name: "Shyam Babariya",
      text: "Recall X makes it easy, fast, and efficient to say to yourself, 'I can learn anything that I need to learn.’ Recall X, one of the foremost authorities in India on this subject.",
      location: "Amreli",
    },
    {
      id: 25,
      img: "https://api.recallx.in/data/Aryan Solanki.jpeg",
      name: "Aryan Solanki",
      text: "Before the training, I was able to read at 150 WPM, and after completion of the course, I was able to read at 350 WPM. This is an awesome product, and I highly recommend it. I don’t get paid anything for saying this. Even if you don’t think you need to know how to read faster, DO IT.",
      location: "Rajkot",
    },
    {
      id: 26,
      img: "https://api.recallx.in/data/Krish Upadhyay.jpeg",
      name: "Krish Upadhyay",
      text: "Recallx wan an great experience for me as I have lots of study material to read which used to take lots of my time nd was not able to remember stuff but after recallx program I'm able to schedule my reading time and also able to remember it in half of my time.becoz of their techniques of note making I'm able to make whole chapter in single sheet nd revise it well... Thank you so much sir for your efforts",
      location: "Chennai",
    },
    {
      id: 27,
      img: "https://api.recallx.in/data/Manav Jivani.jpeg",
      name: "Manav Jivani",
      text: "Superb concept. Innovative, very helpful, less time consuming , smart and too much helpful to the coming generation.",
      location: "Botad",
    },

    {
      id: 28,
      img: "https://api.recallx.in/data/Meet Patel.jpeg",
      name: "Meet Patel",
      text: "So many new things we come to know about...including reading speed it improves the brain exercise and we learn how to rember better...wonderful program.",
      location: "Ahmedabad",
    },
  ];

  return (
    <div>
      <Helmet>
        <title>Testimonial | RecallX</title>
      </Helmet>

      <div className="stricky-header stricked-menu main-menu main-header-two">
        <div className="sticky-header__content" />
      </div>

      <section
        className="page-header page-header--bg-two"
        data-jarallax
        data-speed="0.3"
        data-imgposition="50% -100%"
      >
        <div className="page-header__bg jarallax-img" />
        <div className="page-header__overlay" />
        <div className="container text-center">
          <h2 className="page-header__title">Testimonial</h2>
          <ul className="page-header__breadcrumb list-unstyled">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <span>Testimonial</span>
            </li>
          </ul>
        </div>
      </section>

      <section className="blog-page">
        <div className="container wow fadeInUp" data-wow-delay="300ms">
          <div className="row">
            {data.map((data) => {
              return (
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="item" style={boxStyle}>
                    <div
                      className="testimonial-one__item"
                      style={{ marginTop: "-80px" }}
                    >
                      <div className="testimonial-one__author">
                        <img src={data.img} alt="recallx feedback" />
                      </div>
                      {/* testimonial-author */}
                      <div className="testimonial-one__content">
                        <div className="testimonial-one__quote">
                          {data.text}
                        </div>
                        {/* testimonial-quote */}
                        <div className="testimonial-one__meta">
                          <h5 className="testimonial-one__title">
                            {data.name}
                          </h5>
                          <span className="testimonial-one__designation">
                            {data.location}
                          </span>
                        </div>
                        {/* testimonial-meta */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};
