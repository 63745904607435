import React, { useState } from "react";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function PrivacyPolicy() {
  return (
    <div>
      <Helmet>
        <title>Privacy Policy | RecallX</title>
      </Helmet>

      {/* /.main-header */}
      <div
        className="stricky-header stricked-menu main-menu main-menu-with-bg"
        style={{ background: "#EFEFF3" }}
      ></div>

      <section className="blog-details">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-12 col-lg-12">
              <div className="blog-details__content">
                <div className="blog-details__meta" style={{ color: "#FFF" }}>
                  <div className="blog-details__meta__cats">
                    <a>Reflect On</a>
                  </div>
                  <div className="blog-details__meta__date">
                    <i className="icon-clock" />
                    18 Sept, 2024
                  </div>
                </div>
                {/* /.details-meta */}
                <h1>Privacy Policy</h1>
                <p className="blog-details__text">
                  This privacy policy has been compiled to better serve those
                  who are concerned with how their “Personally identifiable
                  information (PII)” is being used online. PII, as used in US
                  privacy law and information security, is information that can
                  be used on its own or with other information to identify,
                  contact, or locate a single person, or to identify an
                  individual in context. Please read our privacy policy
                  carefully to get a clear understanding of how we collect, use,
                  protect or otherwise handle your Personally Identifiable
                  Information in accordance with our website.
                </p>
                <h3 className="blog-details__title">
                  What personal information do we collect from the people that
                  visit our blog, website or app?
                </h3>
                <p className="blog-details__text">
                  When registering on our site for further follow up as
                  appropriate, you may be asked to enter your name, email
                  address or other details to help you with your experience.
                </p>
                <h3 className="blog-details__title">
                  When do we collect information?
                </h3>
                <p className="blog-details__text">
                  We collect information from you when you subscribe to a
                  newsletter/RSS feed or enter information on our site for
                  further follow up.
                </p>
                <h3 className="blog-details__title">
                  How do we use your information?
                </h3>
                <p className="blog-details__text">
                  We may use the information we collect from you when you
                  register, make a purchase, sign up for our newsletter, respond
                  to a survey or marketing communication, surf the website, or
                  use certain other site features in the following ways: • To
                  personalize user’s experience and to allow us to deliver the
                  type of content and product offerings in which you are most
                  interested. • To improve our website in order to better serve
                  you. • To allow us to better service you in responding to your
                  customer service requests. • To send periodic emails regarding
                  your requirement or other products and services.
                </p>

                <h3 className="blog-details__title">
                  How do we protect visitor information?
                </h3>
                <p className="blog-details__text">
                  We do not use vulnerability scanning and/or scanning to PCI
                  standards. We use regular Malware Scanning. Your personal
                  information is contained behind secured networks and is only
                  accessible by a limited number of persons who have special
                  access rights to such systems, and are required to keep the
                  information confidential In addition, all sensitive/credit
                  information you supply is encrypted via Secure Socket Layer
                  (SSL) technology. We implement a variety of security measures
                  when a user enters, submits, or accesses their information to
                  maintain the safety of your personal information.
                </p>

                <h3 className="blog-details__title">Do we use ‘cookies’?</h3>
                <p className="blog-details__text">
                  Cookies are small files that a site or its service provider
                  transfers to your computer’s hard drive through your Web
                  browser (if you allow) that enables the site’s or service
                  provider’s systems to recognize your browser and capture and
                  remember certain information. They are also used to help us
                  understand your preferences based on previous or current site
                  activity, which enables us to provide you with improved
                  services. We also use cookies to help us compile aggregate
                  data about site traffic and site interaction so that we can
                  offer better site experiences and tools in the future.
                </p>

                <p className="blog-details__text">We may use cookies to:</p>
                <ul>
                  <li>
                    Understand and save user’s preferences for future visits.
                  </li>
                  <li>
                    Compile aggregate data about site traffic and site
                    interactions in order to offer better site experiences and
                    tools in the future. We may also use trusted third party
                    services that track this information on our behalf.
                  </li>
                </ul>
                <p className="blog-details__text">
                  This is a “Mandatory Disclosure” as per EU Privacy Directive
                  95/46/EC and Directive 2002/58/EC of the European Parliament
                </p>
                <p className="blog-details__text">
                  We may collect information about your computer, including your
                  IP address, operating system and browser type, for system
                  administration and in order to create reports. This is
                  statistical data about our users’ browsing actions and
                  patterns, and does not identify any individual.
                </p>
                <p className="blog-details__text">
                  The only cookies in use on our site are for Google Analytics
                  which is persistent in nature. Google Analytics is a web
                  analytics tool that helps website owners understand how
                  visitors engage with their website.
                </p>
                <p className="blog-details__text">
                  Google Analytics customers can view a variety of reports about
                  how visitors interact with their website so that they can
                  improve it.
                </p>
                <p className="blog-details__text">
                  Like many services, Google Analytics uses first-party cookies
                  to track visitor interactions as in our case, where they are
                  used to collect information about how visitors use our site.
                  We then use the information to compile reports and to help us
                  improve our site.
                </p>
                <p className="blog-details__text">
                  Cookies contain information that is transferred to your
                  computer’s hard drive.
                </p>
                <p className="blog-details__text">
                  These cookies are used to store information, such as the time
                  that the current visit occurred, whether the visitor has been
                  to the site before and what site referred the visitor to the
                  web page.
                </p>
                <p className="blog-details__text">
                  Google Analytics collects information anonymously. It reports
                  website trends without identifying individual visitors or any
                  personal information.
                </p>
                <p className="blog-details__text">
                  Opt-Out : You can opt out of Google Analytics without
                  affecting how you visit our site – for more information on
                  opting out of being tracked by Google Analytics across all
                  websites you use, visit this Google page.
                  <a href="http://tools.google.com/dlpage/gaoptout?hl=None">
                    http://tools.google.com/dlpage/gaoptout?hl=None
                  </a>
                </p>
                <p className="blog-details__text">
                  In order to provide website visitors with more choice on how
                  data is collected by Google Analytics, Google has developed
                  the Google Analytics Opt-out Browser Add-on.
                  <a href="http://tools.google.com/dlpage/gaoptout?hl=None">
                    http://tools.google.com/dlpage/gaoptout?hl=None
                  </a>
                </p>
                <p className="blog-details__text">
                  The add-on communicates with the Google Analytics JavaScript
                  (ga.js) to stop data being sent to Google Analytics. The
                  Google Analytics Opt-out Browser Add-on does not affect usage
                  of the website in any other way.
                </p>
                <p className="blog-details__text">
                  A link to further information on the Google Analytics Opt-out
                  Browser Add-on is provided below for your convenience.{" "}
                  <a href="http://tools.google.com/dlpage/gaoptout?hl=None">
                    http://tools.google.com/dlpage/gaoptout?hl=None
                  </a>
                </p>
                <p className="blog-details__text">
                  For more information on the usage of cookies by Google
                  Analytics please see the Google website.
                </p>
                <p className="blog-details__text">
                  A link to the privacy advice for this product is provided
                  below for your convenience.
                  <a href="http://www.google.com/analytics/learn/privacy.html">
                    http://www.google.com/analytics/learn/privacy.html
                  </a>
                </p>
                <h3 className="blog-details__title">Disabling Cookies</h3>
                <p className="blog-details__text">
                  You can control and/or delete cookies as you wish – for
                  details, see aboutcookies.org. You can delete all cookies that
                  are already on your computer and you can set most browsers to
                  prevent them from being placed. If you do this, however, you
                  may have to manually adjust some preferences every time you
                  visit a site and some services and functionalities may not
                  work. If you disable cookies off, some features will be
                  disabled It won’t affect the user’s experience that makes your
                  site experience more efficient and some of our services will
                  not function properly.
                </p>
                <h3 className="blog-details__title">Third Party Disclosure</h3>
                <p className="blog-details__text">
                  We do not sell, trade, or otherwise transfer to outside
                  parties your personally identifiable information unless we
                  provide you with advance notice. This does not include website
                  hosting partners and other parties who assist us in operating
                  our website, conducting our business, or servicing you, so
                  long as those parties agree to keep this information
                  confidential. We may also release your information when we
                  believe release is appropriate to comply with the law, enforce
                  our site policies, or protect ours or others’ rights,
                  property, or safety. However, non-personally identifiable
                  visitor information may be provided to other parties for
                  marketing, advertising, or other uses.
                </p>
                <h3 className="blog-details__title">Third Party Links</h3>
                <p className="blog-details__text">
                  Occasionally, at our discretion, we may include or offer third
                  party products or services on our website. These third party
                  sites have separate and independent privacy policies. We,
                  therefore, have no responsibility or liability for the content
                  and activities of these linked sites. Nonetheless, we seek to
                  protect the integrity of our site and welcome any feedback
                  about these sites.
                </p>
                <h3 className="blog-details__title">Google</h3>
                <p className="blog-details__text">
                  Google, as a third party vendor, uses cookies to serve ads on
                  our site. Google’s use of the DART cookie enables it to serve
                  ads to our users based on their visit to our site and other
                  sites on the Internet. Users may opt out of the use of the
                  DART cookie by visiting the Google ad and content network
                  privacy policy. We may implement the following: • Remarketing
                  with Google AdSense • Google Display Network Impression
                  Reporting and Google Adwords for marketing purposes •
                  Demographics and Interests Reporting We along with third-party
                  vendors, such as Google use first-party cookies (such as the
                  Google Analytics cookies) and third-party cookies (such as the
                  DoubleClick cookie) or other third-party identifiers together
                  to compile data regarding user interactions with ad
                  impressions, and other ad service functions as they relate to
                  our website.
                </p>
                <h3 className="blog-details__title">Opting Out</h3>
                <p className="blog-details__text">
                  Users can set preferences for how Google advertises to you
                  using the Google Ad Settings page. Alternatively, you can opt
                  out by visiting the Network Advertising Initiative opt-out
                  page or permanently using the Google Analytics Opt-Out Browser
                  add-on. Our Privacy Policy link includes the word ‘Privacy’,
                  and can be easily be found on the page specified above. Users
                  will be notified of any privacy policy changes: • On our
                  Privacy Policy Page Users are able to change their personal
                  information if the same has been recorded How does our site
                  handle do not track signals? We honor do not track signals and
                  do not track or plant cookies, or use advertising when a Do
                  Not Track (DNT) browser mechanism is in place. Does our site
                  allow third party behavioral tracking? It’s also important to
                  note that we allow third party behavioral tracking COPPA
                  (Children Online Privacy Protection Act) When it comes to the
                  collection of personal information from children under 13, the
                  Children’s Online Privacy Protection Act (COPPA) puts parents
                  in control. The Federal Trade Commission, the nation’s
                  consumer protection agency, enforces the COPPA Rule, which
                  spells out what operators of websites and online services must
                  do to protect children’s privacy and safety online. We do not
                  specifically market to children under 13.
                </p>
                <h3 className="blog-details__title">
                  Fair Information Practices
                </h3>
                <p className="blog-details__text">
                  The Fair Information Practices Principles form the backbone of
                  privacy law in the United States and the concepts they include
                  have played a significant role in the development of data
                  protection laws around the globe. Understanding the Fair
                  Information Practice Principles and how they should be
                  implemented is critical to comply with the various privacy
                  laws that protect personal information. In order to be in line
                  with Fair Information Practices we will take the following
                  responsive action, should a data breach occur: We will notify
                  the users via email where the same is available • Within 7
                  business days We also agree to the individual redress
                  principle, which requires that individuals have a right to
                  pursue legally enforceable rights against data collectors and
                  processors who fail to adhere to the law. This principle
                  requires not only that individuals have enforceable rights
                  against data users, but also that individuals have recourse to
                  courts or a government agency to investigate and/or prosecute
                  non-compliance by data processors.
                </p>
                <h3 className="blog-details__title">CAN-SPAM Act</h3>
                <p className="blog-details__text">
                  The CAN-SPAM Act is a law that sets the rules for commercial
                  email, establishes requirements for commercial messages, gives
                  recipients the right to have emails stopped from being sent to
                  them, and spells out tough penalties for violations. We
                  collect your email address in order to: • Send information,
                  respond to inquiries, and/or other requests or questions. To
                  be in accordance with CAN-SPAM we agree to the following: •
                  NOT use false, or misleading subjects or email addresses •
                  Identify the message as an advertisement in some reasonable
                  way • Include the physical address of our business or site
                  headquarters • Monitor third party email marketing services
                  for compliance, if one is used. • Honor opt-out/unsubscribe
                  requests quickly • If at any time you would like to
                  unsubscribe from receiving future emails, you can email us at
                  (email id)
                </p>
                <h3 className="blog-details__title">Contacting Us</h3>
                <p className="blog-details__text">
                  If there are any questions regarding this privacy policy you
                  may contact us by writing to us at{" "}
                  <a href="mailto:info@recallx.in">info@recallx.in</a>
                </p>

                <h1 style={{ marginTop: 100 }}>Terms and Conditions</h1>
                <div className="blog-details__meta" style={{ color: "#FFF" }}>
                  <div className="blog-details__meta__cats">
                    <a>Reflect On</a>
                  </div>
                  <div className="blog-details__meta__date">
                    <i className="icon-clock" />
                    19 Oct, 2024
                  </div>
                </div>
                <p>
                  Please read these terms and conditions carefully before using
                  Our Service.
                </p>
                <h2 className="blog-details__title">
                  Interpretation and Definitions
                </h2>
                <h3 className="blog-details__title">Interpretation</h3>
                <p className="blog-details__text">
                  The words of which the initial letter is capitalized have
                  meanings defined under the following conditions. The following
                  definitions shall have the same meaning regardless of whether
                  they appear in singular or in plural.
                </p>
                <h3>Definitions</h3>
                <p className="blog-details__text">
                  For the purposes of these Terms and Conditions:
                </p>
                <ul>
                  <li>
                    <p className="blog-details__text">
                      <strong>Affiliate</strong> means an entity that controls,
                      is controlled by or is under common control with a party,
                      where &quot;control&quot; means ownership of 50% or more
                      of the shares, equity interest or other securities
                      entitled to vote for election of directors or other
                      managing authority.
                    </p>
                  </li>
                  <li>
                    <p className="blog-details__text">
                      <strong>Country</strong> refers to: Gujarat, India
                    </p>
                  </li>
                  <li>
                    <p className="blog-details__text">
                      <strong>Company</strong> (referred to as either &quot;the
                      Company&quot;, &quot;We&quot;, &quot;Us&quot; or
                      &quot;Our&quot; in this Agreement) refers to RecallX, 601,
                      Mondeal Heights, Sarkhej - Gandhinagar Hwy, nr. Novotel
                      Hotel, Ramdev Nagar, Ahmedabad, Gujarat 380015.
                    </p>
                  </li>
                  <li>
                    <p className="blog-details__text">
                      <strong>Device</strong> means any device that can access
                      the Service such as a computer, a cellphone or a digital
                      tablet.
                    </p>
                  </li>
                  <li>
                    <p className="blog-details__text">
                      <strong>Service</strong> refers to the Website.
                    </p>
                  </li>
                  <li>
                    <p className="blog-details__text">
                      <strong>Terms and Conditions</strong> (also referred as
                      &quot;Terms&quot;) mean these Terms and Conditions that
                      form the entire agreement between You and the Company
                      regarding the use of the Service. This Terms and
                      Conditions agreement has been created with the help of the{" "}
                      <a
                        href="https://www.termsfeed.com/terms-conditions-generator/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Terms and Conditions Generator
                      </a>
                      .
                    </p>
                  </li>
                  <li>
                    <p className="blog-details__text">
                      <strong>Third-party Social Media Service</strong> means
                      any services or content (including data, information,
                      products or services) provided by a third-party that may
                      be displayed, included or made available by the Service.
                    </p>
                  </li>
                  <li>
                    <p className="blog-details__text">
                      <strong>Website</strong> refers to RecallX, accessible
                      from{" "}
                      <a
                        href="https://recallx.in"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        https://recallx.in
                      </a>
                    </p>
                  </li>
                  <li>
                    <p className="blog-details__text">
                      <strong>You</strong> means the individual accessing or
                      using the Service, or the company, or other legal entity
                      on behalf of which such individual is accessing or using
                      the Service, as applicable.
                    </p>
                  </li>
                </ul>
                <h2>Acknowledgment</h2>
                <p className="blog-details__text">
                  These are the Terms and Conditions governing the use of this
                  Service and the agreement that operates between You and the
                  Company. These Terms and Conditions set out the rights and
                  obligations of all users regarding the use of the Service.
                </p>
                <p className="blog-details__text">
                  Your access to and use of the Service is conditioned on Your
                  acceptance of and compliance with these Terms and Conditions.
                  These Terms and Conditions apply to all visitors, users and
                  others who access or use the Service.
                </p>
                <p className="blog-details__text">
                  By accessing or using the Service You agree to be bound by
                  these Terms and Conditions. If You disagree with any part of
                  these Terms and Conditions then You may not access the
                  Service.
                </p>
                <p className="blog-details__text">
                  You represent that you are over the age of 18. The Company
                  does not permit those under 18 to use the Service.
                </p>
                <p className="blog-details__text">
                  Your access to and use of the Service is also conditioned on
                  Your acceptance of and compliance with the Privacy Policy of
                  the Company. Our Privacy Policy describes Our policies and
                  procedures on the collection, use and disclosure of Your
                  personal information when You use the Application or the
                  Website and tells You about Your privacy rights and how the
                  law protects You. Please read Our Privacy Policy carefully
                  before using Our Service.
                </p>
                <h2>Links to Other Websites</h2>
                <p className="blog-details__text">
                  Our Service may contain links to third-party web sites or
                  services that are not owned or controlled by the Company.
                </p>
                <p className="blog-details__text">
                  The Company has no control over, and assumes no responsibility
                  for, the content, privacy policies, or practices of any third
                  party web sites or services. You further acknowledge and agree
                  that the Company shall not be responsible or liable, directly
                  or indirectly, for any damage or loss caused or alleged to be
                  caused by or in connection with the use of or reliance on any
                  such content, goods or services available on or through any
                  such web sites or services.
                </p>
                <p className="blog-details__text">
                  We strongly advise You to read the terms and conditions and
                  privacy policies of any third-party web sites or services that
                  You visit.
                </p>
                <h2>Termination</h2>
                <p className="blog-details__text">
                  We may terminate or suspend Your access immediately, without
                  prior notice or liability, for any reason whatsoever,
                  including without limitation if You breach these Terms and
                  Conditions.
                </p>
                <p className="blog-details__text">
                  Upon termination, Your right to use the Service will cease
                  immediately.
                </p>
                <h2>Limitation of Liability</h2>
                <p className="blog-details__text">
                  Notwithstanding any damages that You might incur, the entire
                  liability of the Company and any of its suppliers under any
                  provision of this Terms and Your exclusive remedy for all of
                  the foregoing shall be limited to the amount actually paid by
                  You through the Service or 100 USD if You haven't purchased
                  anything through the Service.
                </p>
                <p className="blog-details__text">
                  To the maximum extent permitted by applicable law, in no event
                  shall the Company or its suppliers be liable for any special,
                  incidental, indirect, or consequential damages whatsoever
                  (including, but not limited to, damages for loss of profits,
                  loss of data or other information, for business interruption,
                  for personal injury, loss of privacy arising out of or in any
                  way related to the use of or inability to use the Service,
                  third-party software and/or third-party hardware used with the
                  Service, or otherwise in connection with any provision of this
                  Terms), even if the Company or any supplier has been advised
                  of the possibility of such damages and even if the remedy
                  fails of its essential purpose.
                </p>
                <p className="blog-details__text">
                  Some states do not allow the exclusion of implied warranties
                  or limitation of liability for incidental or consequential
                  damages, which means that some of the above limitations may
                  not apply. In these states, each party's liability will be
                  limited to the greatest extent permitted by law.
                </p>
                <h2>
                  &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer
                </h2>
                <p className="blog-details__text">
                  The Service is provided to You &quot;AS IS&quot; and &quot;AS
                  AVAILABLE&quot; and with all faults and defects without
                  warranty of any kind. To the maximum extent permitted under
                  applicable law, the Company, on its own behalf and on behalf
                  of its Affiliates and its and their respective licensors and
                  service providers, expressly disclaims all warranties, whether
                  express, implied, statutory or otherwise, with respect to the
                  Service, including all implied warranties of merchantability,
                  fitness for a particular purpose, title and non-infringement,
                  and warranties that may arise out of course of dealing, course
                  of performance, usage or trade practice. Without limitation to
                  the foregoing, the Company provides no warranty or
                  undertaking, and makes no representation of any kind that the
                  Service will meet Your requirements, achieve any intended
                  results, be compatible or work with any other software,
                  applications, systems or services, operate without
                  interruption, meet any performance or reliability standards or
                  be error free or that any errors or defects can or will be
                  corrected.
                </p>
                <p className="blog-details__text">
                  Without limiting the foregoing, neither the Company nor any of
                  the company's provider makes any representation or warranty of
                  any kind, express or implied: (i) as to the operation or
                  availability of the Service, or the information, content, and
                  materials or products included thereon; (ii) that the Service
                  will be uninterrupted or error-free; (iii) as to the accuracy,
                  reliability, or currency of any information or content
                  provided through the Service; or (iv) that the Service, its
                  servers, the content, or e-mails sent from or on behalf of the
                  Company are free of viruses, scripts, trojan horses, worms,
                  malware, timebombs or other harmful components.
                </p>
                <p className="blog-details__text">
                  Some jurisdictions do not allow the exclusion of certain types
                  of warranties or limitations on applicable statutory rights of
                  a consumer, so some or all of the above exclusions and
                  limitations may not apply to You. But in such a case the
                  exclusions and limitations set forth in this section shall be
                  applied to the greatest extent enforceable under applicable
                  law.
                </p>
                <h2>Governing Law</h2>
                <p className="blog-details__text">
                  The laws of the Country, excluding its conflicts of law rules,
                  shall govern this Terms and Your use of the Service. Your use
                  of the Application may also be subject to other local, state,
                  national, or international laws.
                </p>
                <h2>Disputes Resolution</h2>
                <p className="blog-details__text">
                  If You have any concern or dispute about the Service, You
                  agree to first try to resolve the dispute informally by
                  contacting the Company.
                </p>
                <h2>For European Union (EU) Users</h2>
                <p className="blog-details__text">
                  If You are a European Union consumer, you will benefit from
                  any mandatory provisions of the law of the country in which
                  You are resident.
                </p>
                <h2>United States Legal Compliance</h2>
                <p className="blog-details__text">
                  You represent and warrant that (i) You are not located in a
                  country that is subject to the United States government
                  embargo, or that has been designated by the United States
                  government as a &quot;terrorist supporting&quot; country, and
                  (ii) You are not listed on any United States government list
                  of prohibited or restricted parties.
                </p>
                <h2>Severability and Waiver</h2>
                <h3>Severability</h3>
                <p className="blog-details__text">
                  If any provision of these Terms is held to be unenforceable or
                  invalid, such provision will be changed and interpreted to
                  accomplish the objectives of such provision to the greatest
                  extent possible under applicable law and the remaining
                  provisions will continue in full force and effect.
                </p>
                <h3>Waiver</h3>
                <p className="blog-details__text">
                  Except as provided herein, the failure to exercise a right or
                  to require performance of an obligation under these Terms
                  shall not affect a party's ability to exercise such right or
                  require such performance at any time thereafter nor shall the
                  waiver of a breach constitute a waiver of any subsequent
                  breach.
                </p>
                <h2>Translation Interpretation</h2>
                <p className="blog-details__text">
                  These Terms and Conditions may have been translated if We have
                  made them available to You on our Service. You agree that the
                  original English text shall prevail in the case of a dispute.
                </p>
                <h2>Changes to These Terms and Conditions</h2>
                <p className="blog-details__text">
                  We reserve the right, at Our sole discretion, to modify or
                  replace these Terms at any time. If a revision is material We
                  will make reasonable efforts to provide at least 30 days'
                  notice prior to any new terms taking effect. What constitutes
                  a material change will be determined at Our sole discretion.
                </p>
                <p className="blog-details__text">
                  By continuing to access or use Our Service after those
                  revisions become effective, You agree to be bound by the
                  revised terms. If You do not agree to the new terms, in whole
                  or in part, please stop using the website and the Service.
                </p>
                <h2>Contact Us</h2>
                <p className="blog-details__text">
                  If you have any questions about these Terms and Conditions,
                  You can contact us:
                </p>
                <ul>
                  <li>
                    <p className="blog-details__text">
                      By email: info@recallx.in
                    </p>
                  </li>
                  <li>
                    <p className="blog-details__text">
                      By visiting this page on our website:{" "}
                      <Link to="../contact-us" rel="external nofollow noopener">
                        https://recallx.in/contact-us
                      </Link>
                    </p>
                  </li>
                  <li>
                    <p className="blog-details__text">
                      By phone number: (+91) 98798-14480
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
