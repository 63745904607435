import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { DiscountSection } from "./DiscountSection";
import { TestimonialHome } from "./TestimonialHome";

export default function Course() {
  return (
    <div>
      <Helmet>
        <title>Course | RecallX</title>
      </Helmet>
      {/* /.main-header */}
      <div
        className="stricky-header stricked-menu main-menu main-menu-with-bg"
        style={{ background: "#EFEFF3" }}
      ></div>
      {/* /.stricky-header */}
      {/* About Start */}
      <section className="about-three mt-5">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 wow fadeInLeft" data-wow-delay="100ms">
              <div className="about-three__content">
                {/* about content start*/}
                <div className="section-title">
                  <h5 className="section-title__tagline">
                    About Course
                    <svg
                      className="arrow-svg"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 55 13"
                    >
                      <g clipPath="url(#clip0_324_36194)">
                        <path d="M10.5406 6.49995L0.700562 12.1799V8.56995L4.29056 6.49995L0.700562 4.42995V0.819946L10.5406 6.49995Z" />
                        <path d="M25.1706 6.49995L15.3306 12.1799V8.56995L18.9206 6.49995L15.3306 4.42995V0.819946L25.1706 6.49995Z" />
                        <path d="M39.7906 6.49995L29.9506 12.1799V8.56995L33.5406 6.49995L29.9506 4.42995V0.819946L39.7906 6.49995Z" />
                        <path d="M54.4206 6.49995L44.5806 12.1799V8.56995L48.1706 6.49995L44.5806 4.42995V0.819946L54.4206 6.49995Z" />
                      </g>
                    </svg>
                  </h5>
                  <h2 className="section-title__title">
                    Transform Your Reading Skills in Just 26 Days!
                  </h2>
                  <h4 style={{ fontStyle: "italic", marginTop: 16 }}>
                    Fly through books in N-O-T-I-M-E
                  </h4>
                </div>
                {/* section-title */}
                <p className="about-three__content__text">
                  Are you having trouble keeping up with the amount of reading
                  you need to do for both your personal and professional lives?
                  Do you think you could have had more speed with which you
                  absorb information without losing understanding? Being able to
                  read doesn’t mean you’ve mastered how to learn through
                  reading. It's time to simplify reading—academic reading
                  material, fiction, non-fiction books, emails, articles, and
                  textbooks are piling up. Recall X Speed Reading Course helps
                  Improve your reading speed, understanding, and retention. With
                  the skills which you'll learn in this course, you may
                  comfortably tackle all of your reading without feeling
                  burdened. The course we offer will teach you advanced speed
                  reading skills that will dramatically increase your reading
                  comprehension and speed, changing the way you approach and
                  process information. We provide the{" "}
                  <b>top-speed reading course in India.</b>
                </p>
                <p style={{ fontSize: 18, fontStyle: "italic" }}>
                  <span style={{ fontSize: 25, fontWeight: "bold" }}>"</span>
                  The faster you read, the more you learn — maximise your time
                  with Recall X!
                  <span style={{ fontSize: 25, fontWeight: "bold" }}>"</span>
                </p>

                {/* /.icon-box */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="about-three__author">
                      <div className="about-three__author__thumb">
                        <img src="assets/images/nilay-doshi.png" alt="eduact" />
                      </div>
                      <p style={{ fontSize: 21, fontWeight: "bold" }}>
                        Mr. Nilay Doshi
                      </p>
                      <p className="about-three__author__text">
                        Your Coach , Founder
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="about-three__info">
                      <div className="about-three__info__icon">
                        <span className="icon-Call" />
                      </div>
                      <p className="about-three__info__text">
                        Need to Know More Details?
                      </p>
                      <h4 className="about-three__info__title">
                        <a href="tel:919879814480">+(91) 98798 14480</a>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="about-three__br" />
              </div>
              {/* about content end */}
            </div>
            <div className="col-xl-6 wow fadeInRight" data-wow-delay="100ms">
              <div className="about-three__thumb">
                {/* about thumb start */}
                <div
                  className="about-three__thumb__one eduact-tilt"
                  data-tilt-options='{ "glare": false, "maxGlare": 0, "maxTilt": 2, "speed": 700, "scale": 1 }'
                >
                  <img src="assets/images/course-cover.jpg" alt="eduact" />
                </div>
                {/* /.about-thumb-one */}
                <div className="about-three__thumb__shape-one" />
                {/* /.about-shape-one */}
                <div className="about-three__thumb__shape-two" />
                {/* /.about-shape-two */}
                <div className="about-three__thumb__shape-three">
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                </div>
                {/* /.about-shape-three */}
                <div className="about-three__thumb__shape-four">
                  <img
                    src="assets/images/shapes/about-3-shape-1.png"
                    alt="eduact"
                  />
                </div>
                {/* /.about-shape-four */}
                <div className="about-three__thumb__shape-five">
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                </div>
                {/* /.about-shape-five */}
                <div className="about-three__thumb__shape-six">
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                </div>
                {/* /.about-shape-six */}
                <div className="about-three__thumb__shape-seven" />
                {/* /.about-shape-seven */}
              </div>
              {/* about thumb end */}
            </div>
          </div>
        </div>
      </section>
      <section className="video-one" style={{ marginTop: -60 }}>
        <div className="container">
          <div
            className="video-one__bg"
            style={{
              backgroundImage: "url(assets/images/backgrounds/video-bg-1.jpg)",
            }}
          >
            <div
              className="video-one__shape"
              style={{
                backgroundImage: "url(assets/images/shapes/video-shape-1.png)",
              }}
            />
            <div
              className="video-one__shape2"
              style={{
                backgroundImage: "url(assets/images/shapes/video-shape-2.png)",
              }}
            />
            <div className="row">
              <div
                className="col-lg-6 col-md-7 wow fadeInLeft"
                data-wow-delay="200ms"
              >
                <h3 className="video-one__title">
                  Watch the full introduction of the course
                </h3>
                <span style={{ fontSize: 65, color: "#f57005" }}>{">>>"}</span>
              </div>
              <div
                className="col-lg-6 col-md-5 wow fadeInRight"
                data-wow-delay="200ms"
              >
                <div className="video-one__btn">
                  <img src="assets/images/33.jpg" alt="eduact" />
                  <Link to="/videos" className="video-popup">
                    <span className="icon-play" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About Start */}
      <section style={{ marginTop: 100, marginBottom: 100 }}>
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="about-two__content">
                {/* about content start*/}
                <div className="section-title" style={{ marginTop: -40 }}>
                  <h2 className="section-title__title">Course Description</h2>
                </div>
                {/* section-title */}
                <p className="about-two__content__text">
                  The Recall X Speed Reading Course is a life-changing 26-day
                  program that combines focused instruction with real-world
                  application each day to improve the way you read. Every day
                  introduces you to fresh techniques and ideas, thus improving
                  your comprehension and speed of reading. With daily sessions
                  planned to optimize effectiveness and impact, our structured
                  program guarantees that you improve steadily. As they say,
                  <b>
                    "Unlocking your full potential starts with mastering the
                    basics."
                  </b>
                </p>
                <p className="about-two__content__text">
                  With just one session each day, you'll significantly increase
                  your reading speed and memory, from basic concepts to
                  professional methods.
                </p>
                <p className="about-two__content__text">
                  Your learning will be enhanced and your progress will be
                  noticeable throughout the course through learning real-world
                  drills. Your reading speed can increase up to three times
                  using the Recall X method, which also improves comprehension
                  and memory. You will gain the ability to process information
                  more quickly and perform well in both personal and
                  professional duties by the end of the course when you have
                  mastered advanced note-taking skills and critical speed
                  reading techniques.
                </p>
                <Link to="/contact-us" className="eduact-btn eduact-btn-second">
                  Inquiry
                  <i className="icon-arrow" />
                </Link>
                <Link
                  to="/checkout"
                  className="eduact-btn eduact-btn-second"
                  style={{ background: "#0f1083", marginLeft: 15 }}
                >
                  Buy Now
                  <i className="icon-arrow" />
                </Link>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="about-one__box">
                <div className="about-one__box__wrapper">
                  <div className="about-one__box__icon">
                    <span className="icon-Presentation"></span>
                  </div>
                  <h4 className="about-one__box__title">Planned Program</h4>
                  <p className="about-one__box__text">
                    A planned 26-day program with daily 25-minute sessions meant
                    for steady improvement.
                  </p>
                </div>
              </div>
              <div className="about-one__box">
                <div className="about-one__box__wrapper">
                  <div className="about-one__box__icon">
                    <span className="icon-Presentation"></span>
                  </div>
                  <h4 className="about-one__box__title">
                    Scientifically Supported Methods
                  </h4>
                  <p className="about-one__box__text">
                    Dependable, scientifically supported methods for improving
                    memory, comprehension, and reading speed.
                  </p>
                </div>
              </div>
              <div className="about-one__box">
                <div className="about-one__box__wrapper">
                  <div className="about-one__box__icon">
                    <span className="icon-Presentation"></span>
                  </div>
                  <h4 className="about-one__box__title">Suitable For All</h4>
                  <p className="about-one__box__text">
                    Suitable for students, professionals, and anyone looking for
                    ways to enhance learning effectiveness.
                  </p>
                </div>
              </div>
              <div className="about-one__box">
                <div className="about-one__box__wrapper">
                  <div className="about-one__box__icon">
                    <span className="icon-Presentation"></span>
                  </div>
                  <h4 className="about-one__box__title">Long-term success</h4>
                  <p className="about-one__box__text">
                    For long-term success, improve reading speed, boost memory,
                    and focus.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <DiscountSection />
      {/* About End */}
      <section className="service-one">
        <div className="container">
          <div
            className="section-title text-center wow fadeInUp"
            data-wow-delay="100ms"
          >
            <h2 className="section-title__title">
              This course is for YOU if you . . .
            </h2>
          </div>
          <ul className="about-two__lists clearfix">
            <li>
              <span className="icon-check" />
              Wish to read more quickly while absorbing more properly.
            </li>
            <li>
              <span className="icon-check" />
              Face challenges handling a vast reading list.
            </li>
            <li>
              <span className="icon-check" />
              Need to recall more in less time.
            </li>
            <li>
              <span className="icon-check" />
              Desire to boost exam skills.
            </li>
            <li>
              <span className="icon-check" />
              Experience reading-related distractions.
            </li>
            <li>
              <span className="icon-check" />
              Process business reports as soon as possible.
            </li>
            <li>
              <span className="icon-check" />
              Seek to boost the effectiveness of learning.
            </li>
            <li>
              <span className="icon-check" />
              Read more intelligently, not more forcefully.
            </li>
            <li>
              <span className="icon-check" />
              Wish to read with more concentration.
            </li>
            <li>
              <span className="icon-check" />
              Are a professional looking to stay ahead.
            </li>
          </ul>
        </div>
      </section>

      <section className="service-one">
        <div className="service-one__bg" />
        <div className="container">
          <div
            className="section-title wow fadeInUp text-center"
            data-wow-delay="100ms"
          >
            <h2 className="section-title__title">Course Outline</h2>
            <p style={{ fontSize: 23, fontWeight: "bold", marginTop: 8 }}>
              Our 26-day program is carefully designed to make sure you get the
              most out of every session. <br />
              Here is a sneak peek at what to expect
            </p>
          </div>

          <div className="row d-flex align-items-stretch">
            <div
              className="col-lg-4 col-md-6 mt-4 mb-4 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="service-one__item about-one__box h-100">
                <div className="service-one__wrapper h-100">
                  {/* /.service-icon */}
                  <h3 className="service-one__title">
                    Day 1: Overview and Science Behind Recall X:{" "}
                  </h3>
                  {/* /.service-title */}
                  <div className="service-one__text">
                    <ul>
                      <li>
                        Start your speed reading journey with a full rundown of
                        our course's benefits.
                      </li>
                      <li>
                        The Scientific Basis of Rapid Reading: Find out how
                        using speed reading strategies may enhance your
                        cognitive function and increase your level of
                        intelligence.
                      </li>
                    </ul>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 118 129"
                    fill="none"
                  >
                    <path
                      d="M0.582052 143.759C135.395 113.682 145.584 0.974413 145.584 0.974413L173.881 89.6286C173.881 89.6286 0.582054 322.604 0.582052 143.759Z"
                      fill="#F1F2FD"
                    />
                  </svg>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
            <div
              className="col-lg-4 col-md-6 mt-4 mb-4 wow fadeInUp"
              data-wow-delay="200ms"
            >
              <div className="service-one__item about-one__box h-100">
                <div className="service-one__wrapper h-100">
                  {/* /.service-icon */}
                  <h3 className="service-one__title">
                    Day 2: Study Strategies: Moving from Distraction to
                    Dedication
                  </h3>
                  {/* /.service-title */}
                  <div className="service-one__text">
                    <ul>
                      <li>
                        Discover the Pomodoro Study Secret to increase
                        productivity and focus.
                      </li>
                      <li>
                        The Science of Retention: To maximize memory retention,
                        understand the curve of forgetting and spaced repetition
                        concepts.
                      </li>
                    </ul>
                  </div>

                  {/* /.service-content */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 118 129"
                    fill="none"
                  >
                    <path
                      d="M0.582052 143.759C135.395 113.682 145.584 0.974413 145.584 0.974413L173.881 89.6286C173.881 89.6286 0.582054 322.604 0.582052 143.759Z"
                      fill="#F1F2FD"
                    />
                  </svg>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
            <div
              className="col-lg-4 col-md-6 mt-4 mb-4 wow fadeInUp"
              data-wow-delay="300ms"
            >
              <div className="service-one__item about-one__box h-100">
                <div className="service-one__wrapper h-100">
                  <h3 className="service-one__title">
                    Day 3: Basic Techniques and Assessments
                  </h3>
                  {/* /.service-title */}
                  <div className="service-one__text">
                    <ul>
                      <li>
                        7 Measures for Enhanced Learning: Understand smart
                        tactics that will boost the retention rate to 90%.
                      </li>
                      <li>
                        Preliminary Words-Per-Minute Test (WPM Test): This is
                        the first step towards learning fast reading.
                      </li>
                    </ul>
                  </div>

                  {/* /.service-content */}

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 118 129"
                    fill="none"
                  >
                    <path
                      d="M0.582052 143.759C135.395 113.682 145.584 0.974413 145.584 0.974413L173.881 89.6286C173.881 89.6286 0.582054 322.604 0.582052 143.759Z"
                      fill="#F1F2FD"
                    />
                  </svg>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
            <div
              className="col-lg-4 col-md-6 mt-4 mb-4 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div className="service-one__item about-one__box h-100">
                <div className="service-one__wrapper h-100">
                  {/* /.service-icon */}
                  <h3 className="service-one__title">
                    Day 4: Getting Over Challenges
                  </h3>
                  {/* /.service-title */}
                  <div className="service-one__text">
                    <ul>
                      <li>
                        Slow Reading's Hidden Villains: Find out and understand
                        the typical obstacles that cause reading to slow.
                      </li>
                      <li>
                        Boost Your Reading Speed: Acquire the skills necessary
                        to get over these barriers and speed your reading.
                      </li>
                    </ul>
                  </div>
                  {/* /.service-content */}

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 118 129"
                    fill="none"
                  >
                    <path
                      d="M0.582052 143.759C135.395 113.682 145.584 0.974413 145.584 0.974413L173.881 89.6286C173.881 89.6286 0.582054 322.604 0.582052 143.759Z"
                      fill="#F1F2FD"
                    />
                  </svg>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
            <div
              className="col-lg-4 col-md-6 mt-4 mb-4 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="service-one__item about-one__box h-100">
                <div className="service-one__wrapper h-100">
                  <h3 className="service-one__title">
                    Day 5: Improving Technique and Concentration
                  </h3>
                  {/* /.service-title */}
                  <div className="service-one__text">
                    <ul>
                      <li>
                        Boost Your Focus While Reading: Learn the Visual Pacer
                        Technique to boost the pace and focus of your reading.
                      </li>
                      <li>
                        From Awkward To Natural: Use the Visual Pacer frequently
                        to hone your fluent and effortless reading style.
                      </li>
                    </ul>
                  </div>
                  {/* /.service-content */}

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 118 129"
                    fill="none"
                  >
                    <path
                      d="M0.582052 143.759C135.395 113.682 145.584 0.974413 145.584 0.974413L173.881 89.6286C173.881 89.6286 0.582054 322.604 0.582052 143.759Z"
                      fill="#F1F2FD"
                    />
                  </svg>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
            <div
              className="col-lg-4 col-md-6 mt-4 mb-4 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="service-one__item about-one__box h-100">
                <div className="service-one__wrapper h-100">
                  <h3 className="service-one__title">
                    And the course continues with specialized techniques and
                    drills through Day 26
                  </h3>
                  {/* /.service-title */}
                  <p className="service-one__text">
                    <b>
                      Each day builds on the previous one, ensuring a steady
                      progression towards mastering speed reading.
                    </b>
                  </p>
                  <Link
                    to="/checkout"
                    className="eduact-btn eduact-btn-second mt-3"
                    style={{ background: "#0f1083", marginLeft: 15 }}
                  >
                    Buy Course Now
                    <i className="icon-arrow" />
                  </Link>
                  {/* /.service-content */}

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 118 129"
                    fill="none"
                  >
                    <path
                      d="M0.582052 143.759C135.395 113.682 145.584 0.974413 145.584 0.974413L173.881 89.6286C173.881 89.6286 0.582054 322.604 0.582052 143.759Z"
                      fill="#F1F2FD"
                    />
                  </svg>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
          </div>
        </div>
      </section>
      {/* Service Start */}
      <section
        className="service-two "
        style={{
          backgroundImage: "url(assets/images/shapes/service-bg-2.jpg)",
        }}
      >
        <div className="container">
          <div
            className="section-title text-center wow fadeInUp"
            data-wow-delay="100ms"
          >
            <h2 className="section-title__title">
              Why Thousands Trust Recall X
            </h2>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div
                className="section-title wow fadeInLeft"
                data-wow-delay="100ms"
              >
                <h5 className="section-title__tagline">
                  Why Choose Recall X?
                  <svg
                    className="arrow-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 55 13"
                  >
                    <g clipPath="url(#clip0_324_36194)">
                      <path d="M10.5406 6.49995L0.700562 12.1799V8.56995L4.29056 6.49995L0.700562 4.42995V0.819946L10.5406 6.49995Z" />
                      <path d="M25.1706 6.49995L15.3306 12.1799V8.56995L18.9206 6.49995L15.3306 4.42995V0.819946L25.1706 6.49995Z" />
                      <path d="M39.7906 6.49995L29.9506 12.1799V8.56995L33.5406 6.49995L29.9506 4.42995V0.819946L39.7906 6.49995Z" />
                      <path d="M54.4206 6.49995L44.5806 12.1799V8.56995L48.1706 6.49995L44.5806 4.42995V0.819946L54.4206 6.49995Z" />
                    </g>
                  </svg>
                </h5>
                <h2 className="section-title__title">
                  Tested Methods for
                  <br /> True Results
                </h2>
              </div>
              {/* section-title */}
            </div>
            <div className="col-lg-6 wow fadeInRight" data-wow-delay="100ms">
              <p className="service-three__section-text">
                Recall X is a course which provides long-lasting improvements to
                your reading and learning skills by combining the most recent
                findings in cognitive science with practical learning
                techniques. We help you improve your reading skills in a few
                weeks by offering you tailored instruction and ongoing
                assistance.
              </p>
            </div>
          </div>
          {/* section-title */}
          <div className="row">
            <div
              className="col-xl-3 col-md-6 wow fadeInUp"
              data-wow-delay="200ms"
            >
              <div className="service-two__item text-center">
                <div className="service-two__wrapper">
                  <svg
                    viewBox="0 0 303 117"
                    fill="#F6F6F6"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx={151} cy={-129} r={246} />
                  </svg>
                  <div className="service-two__icon">
                    <span className="icon-education" />
                  </div>
                  {/* /.service-icon */}
                  <h3 className="service-two__title">3x Faster Reading</h3>
                  {/* /.service-title */}
                  <p className="service-two__text">
                    Increase reading speed three times while maintaining
                    comprehension.
                    <br />
                    <br />
                  </p>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
            <div
              className="col-xl-3 col-md-6 wow fadeInUp"
              data-wow-delay="300ms"
            >
              <div className="service-two__item text-center">
                <div className="service-two__wrapper">
                  <svg
                    viewBox="0 0 303 117"
                    fill="#F6F6F6"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx={151} cy={-129} r={246} />
                  </svg>
                  <div className="service-two__icon">
                    <span className="icon-business" />
                  </div>
                  {/* /.service-icon */}
                  <h3 className="service-two__title">Comprehension Boost:</h3>
                  {/* /.service-title */}
                  <p className="service-two__text">
                    Use cutting-edge memory tricks to retain crucial
                    information.
                    <br />
                    <br />
                  </p>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
            <div
              className="col-xl-3 col-md-6 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div className="service-two__item text-center">
                <div className="service-two__wrapper">
                  <svg
                    viewBox="0 0 303 117"
                    fill="#F6F6F6"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx={151} cy={-129} r={246} />
                  </svg>
                  <div className="service-two__icon">
                    <span className="icon-neural" />
                  </div>
                  {/* /.service-icon */}
                  <h3 className="service-two__title">
                    Scientifically Verified
                  </h3>
                  {/* /.service-title */}
                  <p className="service-two__text">
                    The learning and retention techniques we teach in our course
                    are thoroughly tried and tested.
                  </p>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
            <div
              className="col-xl-3 col-md-6 wow fadeInUp"
              data-wow-delay="500ms"
            >
              <div className="service-two__item text-center">
                <div className="service-two__wrapper">
                  <svg
                    viewBox="0 0 303 117"
                    fill="#F6F6F6"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx={151} cy={-129} r={246} />
                  </svg>
                  <div className="service-two__icon">
                    <span className="icon-webinar" />
                  </div>
                  {/* /.service-icon */}
                  <h3 className="service-two__title">Tailored for All</h3>
                  {/* /.service-title */}
                  <p className="service-two__text">
                    Excellent for researchers, professionals, students, and
                    people who love to learn.
                    <br />
                    <br />
                  </p>
                  {/* /.service-content */}
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
          </div>
        </div>
      </section>
      {/* Service End */}
      {/* Video Start */}
      <section className="service-one">
        <div className="service-one__bg" />
        <div className="container">
          <div
            className="section-title wow fadeInUp text-center"
            data-wow-delay="100ms"
          >
            <h2 className="section-title__title">
              Who will benefit from This Speed Reading Course?
            </h2>
            <p style={{ fontSize: 21, paddingTop: 8, paddingBottom: 8 }}>
              It is one of the top-speed reading courses online. Designed to
              meet the needs of a wide variety of learners, <br />
              The Recall X Speed Reading Course includes
            </p>
          </div>

          <div className="row">
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="service-one__item about-one__box">
                <div className="service-one__wrapper">
                  <div className="service-one__icon">
                    <span className="icon-education" />
                  </div>
                  {/* /.service-icon */}
                  <h3 className="service-one__title">Students</h3>
                  {/* /.service-title */}
                  <p className="service-one__text">
                    Learn more in less time by using your knowledge of the
                    subject matter quickly.
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 118 129"
                    fill="none"
                  >
                    <path
                      d="M0.582052 143.759C135.395 113.682 145.584 0.974413 145.584 0.974413L173.881 89.6286C173.881 89.6286 0.582054 322.604 0.582052 143.759Z"
                      fill="#F1F2FD"
                    />
                  </svg>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="200ms"
            >
              <div className="service-one__item about-one__box">
                <div className="service-one__wrapper">
                  <div className="service-one__icon">
                    <span className="icon-business" />
                  </div>
                  {/* /.service-icon */}
                  <h3 className="service-one__title">College Going</h3>
                  {/* /.service-title */}
                  <p className="service-one__text">
                    Learning in college becomes fun as this course lets you
                    learn with more ease and better understanding.
                  </p>
                  {/* /.service-content */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 118 129"
                    fill="none"
                  >
                    <path
                      d="M0.582052 143.759C135.395 113.682 145.584 0.974413 145.584 0.974413L173.881 89.6286C173.881 89.6286 0.582054 322.604 0.582052 143.759Z"
                      fill="#F1F2FD"
                    />
                  </svg>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="300ms"
            >
              <div className="service-one__item about-one__box">
                <div className="service-one__wrapper">
                  <div className="service-one__icon">
                    <span className="icon-webinar" />
                  </div>
                  {/* /.service-icon */}
                  <h3 className="service-one__title">Exam Preparers</h3>
                  {/* /.service-title */}
                  <p className="service-one__text">
                    Accelerate your study for arduous exams, such as government
                    examinations like GPSC, and UPSC.
                  </p>
                  {/* /.service-content */}

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 118 129"
                    fill="none"
                  >
                    <path
                      d="M0.582052 143.759C135.395 113.682 145.584 0.974413 145.584 0.974413L173.881 89.6286C173.881 89.6286 0.582054 322.604 0.582052 143.759Z"
                      fill="#F1F2FD"
                    />
                  </svg>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div className="service-one__item about-one__box">
                <div className="service-one__wrapper">
                  <div className="service-one__icon">
                    <span className="icon-neural" />
                  </div>
                  {/* /.service-icon */}
                  <h3 className="service-one__title">Professionals</h3>
                  {/* /.service-title */}
                  <p className="service-one__text">
                    Be more productive by handling contracts, reports, and
                    important papers promptly.
                  </p>
                  {/* /.service-content */}

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 118 129"
                    fill="none"
                  >
                    <path
                      d="M0.582052 143.759C135.395 113.682 145.584 0.974413 145.584 0.974413L173.881 89.6286C173.881 89.6286 0.582054 322.604 0.582052 143.759Z"
                      fill="#F1F2FD"
                    />
                  </svg>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
          </div>

          <div className="row">
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="service-one__item about-one__box">
                <div className="service-one__wrapper">
                  <div className="service-one__icon">
                    <span className="icon-education" />
                  </div>
                  {/* /.service-icon */}
                  <h3 className="service-one__title">
                    Researchers and journalists
                  </h3>
                  {/* /.service-title */}
                  <p className="service-one__text">
                    Acquire research papers and sources faster and more
                    correctly.
                    <br />
                    <br />
                  </p>
                  {/* /.service-content */}

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 118 129"
                    fill="none"
                  >
                    <path
                      d="M0.582052 143.759C135.395 113.682 145.584 0.974413 145.584 0.974413L173.881 89.6286C173.881 89.6286 0.582054 322.604 0.582052 143.759Z"
                      fill="#F1F2FD"
                    />
                  </svg>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="200ms"
            >
              <div className="service-one__item about-one__box">
                <div className="service-one__wrapper">
                  <div className="service-one__icon">
                    <span className="icon-business" />
                  </div>
                  {/* /.service-icon */}
                  <h3 className="service-one__title">Seniors</h3>
                  {/* /.service-title */}
                  <p className="service-one__text">
                    Use tried-and-true methods to improve cognitive flexibility
                    and memory recall.
                    <br />
                    <br />
                  </p>
                  {/* /.service-content */}

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 118 129"
                    fill="none"
                  >
                    <path
                      d="M0.582052 143.759C135.395 113.682 145.584 0.974413 145.584 0.974413L173.881 89.6286C173.881 89.6286 0.582054 322.604 0.582052 143.759Z"
                      fill="#F1F2FD"
                    />
                  </svg>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="300ms"
            >
              <div className="service-one__item about-one__box">
                <div className="service-one__wrapper">
                  <div className="service-one__icon">
                    <span className="icon-webinar" />
                  </div>
                  {/* /.service-icon */}
                  <h3 className="service-one__title">Entrepreneurs</h3>
                  {/* /.service-title */}
                  <p className="service-one__text">
                    Read industry papers and business literature rapidly to stay
                    informed of market developments and business insights.
                  </p>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 118 129"
                    fill="none"
                  >
                    <path
                      d="M0.582052 143.759C135.395 113.682 145.584 0.974413 145.584 0.974413L173.881 89.6286C173.881 89.6286 0.582054 322.604 0.582052 143.759Z"
                      fill="#F1F2FD"
                    />
                  </svg>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div className="service-one__item about-one__box">
                <div className="service-one__wrapper">
                  <div className="service-one__icon">
                    <span className="icon-neural" />
                  </div>
                  {/* /.service-icon */}
                  <h3 className="service-one__title">
                    Health and Wellbeing Experts
                  </h3>
                  {/* /.service-title */}
                  <p className="service-one__text">
                    Become more proficient at staying up to date with the latest
                    medical research and treatment recommendations.
                  </p>
                  {/* /.service-content */}

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 118 129"
                    fill="none"
                  >
                    <path
                      d="M0.582052 143.759C135.395 113.682 145.584 0.974413 145.584 0.974413L173.881 89.6286C173.881 89.6286 0.582054 322.604 0.582052 143.759Z"
                      fill="#F1F2FD"
                    />
                  </svg>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
          </div>
        </div>
      </section>
      {/* Video End */}
      <section className="service-one">
        <div
          className="service-one__bg"
          style={{
            backgroundImage: "url(assets/images/shapes/service-bg-1.png)",
          }}
        />
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <div
                className="section-title wow fadeInUp"
                data-wow-delay="100ms"
              >
                <h2 className="section-title__title">
                  What Changes Will the Course Bring to You After Completion?
                </h2>
                <p style={{ fontSize: 18, marginTop: 25 }}>
                  The human brain and eye can read in a coordinated way with a
                  high comprehension rate of over 500 words per minute. To
                  obtain such a rapid reading speed, you will undoubtedly want
                  to know or learn some unique strategies. A person with a high
                  reading speed has all of the advantages of receiving better
                  grades as a student or advancing fast in his or her job as a
                  businessperson.
                </p>
              </div>
              <h5 className="section-title__tagline">
                At the end of our 26-day course,
                <br /> you can expect:
                <svg
                  className="arrow-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 55 13"
                >
                  <g clipPath="url(#clip0_324_36194)">
                    <path d="M10.5406 6.49995L0.700562 12.1799V8.56995L4.29056 6.49995L0.700562 4.42995V0.819946L10.5406 6.49995Z" />
                    <path d="M25.1706 6.49995L15.3306 12.1799V8.56995L18.9206 6.49995L15.3306 4.42995V0.819946L25.1706 6.49995Z" />
                    <path d="M39.7906 6.49995L29.9506 12.1799V8.56995L33.5406 6.49995L29.9506 4.42995V0.819946L39.7906 6.49995Z" />
                    <path d="M54.4206 6.49995L44.5806 12.1799V8.56995L48.1706 6.49995L44.5806 4.42995V0.819946L54.4206 6.49995Z" />
                  </g>
                </svg>
              </h5>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="row">
                <div
                  className="col-lg-6 col-md-6 wow fadeInUp"
                  data-wow-delay="100ms"
                >
                  <div className="service-one__item about-one__box">
                    <div className="service-one__wrapper">
                      {/* /.service-icon */}
                      <h3 className="service-one__title">
                        Improved Reading Speed
                      </h3>
                      {/* /.service-title */}
                      <p className="service-one__text">
                        Increase your reading speed while preserving important
                        details.
                        <br />
                        <br />
                        <br />
                      </p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 118 129"
                        fill="none"
                      >
                        <path
                          d="M0.582052 143.759C135.395 113.682 145.584 0.974413 145.584 0.974413L173.881 89.6286C173.881 89.6286 0.582054 322.604 0.582052 143.759Z"
                          fill="#F1F2FD"
                        />
                      </svg>
                    </div>
                  </div>
                  {/* /.service-card-one */}
                </div>
                <div
                  className="col-lg-6 col-md-6 wow fadeInUp"
                  data-wow-delay="200ms"
                >
                  <div className="service-one__item about-one__box">
                    <div className="service-one__wrapper">
                      {/* /.service-icon */}
                      <h3 className="service-one__title">
                        Masterful Note-Taking & Note making
                      </h3>
                      {/* /.service-title */}
                      <p className="service-one__text">
                        Learn excellent note-taking skills to manage and
                        comprehend complex subjects with ease.
                      </p>
                      {/* /.service-content */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 118 129"
                        fill="none"
                      >
                        <path
                          d="M0.582052 143.759C135.395 113.682 145.584 0.974413 145.584 0.974413L173.881 89.6286C173.881 89.6286 0.582054 322.604 0.582052 143.759Z"
                          fill="#F1F2FD"
                        />
                      </svg>
                    </div>
                  </div>
                  {/* /.service-card-one */}
                </div>
                <div
                  className="col-lg-6 col-md-6 wow fadeInUp"
                  data-wow-delay="300ms"
                >
                  <div className="service-one__item about-one__box">
                    <div className="service-one__wrapper">
                      {/* /.service-icon */}
                      <h3 className="service-one__title">
                        Improved Cognitive Skills:
                      </h3>
                      {/* /.service-title */}
                      <p className="service-one__text">
                        Utilize advanced learning strategies to improve your
                        comprehension and memory.
                        <br />
                        <br />
                      </p>
                      {/* /.service-content */}

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 118 129"
                        fill="none"
                      >
                        <path
                          d="M0.582052 143.759C135.395 113.682 145.584 0.974413 145.584 0.974413L173.881 89.6286C173.881 89.6286 0.582054 322.604 0.582052 143.759Z"
                          fill="#F1F2FD"
                        />
                      </svg>
                    </div>
                  </div>
                  {/* /.service-card-one */}
                </div>
                <div
                  className="col-lg-6 col-md-6 wow fadeInUp"
                  data-wow-delay="400ms"
                >
                  <div className="service-one__item about-one__box">
                    <div className="service-one__wrapper">
                      {/* /.service-icon */}
                      <h3 className="service-one__title">
                        Increased Productivity:
                      </h3>
                      {/* /.service-title */}
                      <p className="service-one__text">
                        Use your newly acquired reading abilities to excel in
                        both personal and professional responsibilities, making
                        you more efficient and smart.
                      </p>
                      {/* /.service-content */}

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 118 129"
                        fill="none"
                      >
                        <path
                          d="M0.582052 143.759C135.395 113.682 145.584 0.974413 145.584 0.974413L173.881 89.6286C173.881 89.6286 0.582054 322.604 0.582052 143.759Z"
                          fill="#F1F2FD"
                        />
                      </svg>
                    </div>
                  </div>
                  {/* /.service-card-one */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Course Start */}
      <section
        className="course-three"
        style={{
          backgroundImage: "url(assets/images/shapes/course-bg-3.png)",
        }}
      >
        <div className="container">
          <div
            className="section-title wow fadeInUp text-center"
            data-wow-delay="100ms"
          >
            <h2 className="section-title__title">Why Choose RecallX ?</h2>
          </div>
          {/* section-title */}
          <div className="row">
            <div className="col-xl-6 wow fadeInUp" data-wow-delay="200ms">
              <div className="course-three__item">
                <div className="course-three__thumb">
                  <img src="assets/images/course/course-3-1.png" alt="eduact" />
                </div>
                {/* /.course-thumb */}
                <div className="course-three__content">
                  <h3 className="course-three__title">Proven Expertise</h3>
                  <p>
                    Top professionals such as Bill Gates, Howard Stephen Berg
                    and Ann Jones employ the same strategies, which are based on
                    scientific study. You will learn from the greatest and
                    achieve exceptional results.
                  </p>
                </div>
                {/* /.course-content */}
              </div>
              {/* /.course-card-two */}
            </div>
            <div className="col-xl-6 wow fadeInUp" data-wow-delay="300ms">
              <div className="course-three__item">
                <div className="course-three__thumb">
                  <img src="assets/images/course/course-3-2.png" alt="eduact" />
                </div>
                {/* /.course-thumb */}
                <div className="course-three__content">
                  <h3 className="course-three__title">Structured Excellence</h3>
                  <p>
                    Our 26-day program is carefully structured to promote rapid
                    progress and long-term improvement. Each day strengthens the
                    last, helping you to master using clear, achievable actions.
                  </p>
                </div>
                {/* /.course-content */}
              </div>
              {/* /.course-card-two */}
            </div>
            <div className="col-xl-6 wow fadeInUp" data-wow-delay="400ms">
              <div className="course-three__item">
                <div className="course-three__thumb">
                  <img src="assets/images/course/course-3-3.png" alt="eduact" />
                </div>
                {/* /.course-thumb */}
                <div className="course-three__content">
                  <h3 className="course-three__title">Comprehensive Support</h3>
                  <p>
                    Get continued access to materials and individual help even
                    after you complete the course. We are dedicated to helping
                    you succeed every step of your journey.
                  </p>
                </div>
                {/* /.course-content */}
              </div>
              {/* /.course-card-two */}
            </div>
            <div className="col-xl-6 wow fadeInUp" data-wow-delay="500ms">
              <div className="course-three__item">
                <div className="course-three__thumb">
                  <img src="assets/images/course/course-3-4.png" alt="eduact" />
                </div>
                {/* /.course-thumb */}
                <div className="course-three__content">
                  <h3 className="course-three__title">Transformation Impact</h3>
                  <p>
                    Your reading speed, comprehension, creativity, focus,
                    concentration, How to take notes and make notes, how to
                    solve the problem, and productivity will all significantly
                    improve. Recall X will help you not only read faster but
                    also comprehend and retain information better.
                  </p>
                </div>
                {/* /.course-content */}
              </div>
              {/* /.course-card-two */}
            </div>
          </div>
        </div>
      </section>
      {/* Course End */}

      {/* Testimonial Start */}
      <TestimonialHome />
      {/* Testimonial End */}

      {/* /.client-carousel */}
      {/* Call To Action Start */}
      <section
        className="cta-three"
        data-jarallax
        data-speed="0.3"
        data-imgposition="50% -100%"
      >
        <div
          className="cta-three__bg jarallax-img"
          style={{
            backgroundImage: "url(assets/images/backgrounds/cta-bg-3.jpg)",
          }}
        />
        <div className="container">
          <div className="row">
            <div className="col-md-6 wow fadeInLeft" data-wow-delay="200ms">
              <div className="cta-three__thumb">
                <img src="assets/images/shapes/cta-3-1.png" alt="eduact" />
              </div>
              <h3 className="cta-three__title">
                <span>Skills Certificate</span>
                <br /> From the <span>RecallX</span>
              </h3>
            </div>
            <div className="col-md-6 wow fadeInRight" data-wow-delay="300ms">
              <div className="cta-three__btn">
                <Link to="/checkout" className="eduact-btn">
                  <span className="eduact-btn__curve" />
                  Buy Course
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
