import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const FAQ = () => {
  const [active, setActive] = useState(1);

  return (
    <div>
      <Helmet>
        <title>FAQ | RecallX</title>
      </Helmet>

      {/* /.main-header-two */}
      <div className="stricky-header stricked-menu main-menu main-header-two">
        <div className="sticky-header__content" />
        {/* /.sticky-header__content */}
      </div>

      <section
        className="page-header page-header--bg-two"
        data-jarallax
        data-speed="0.3"
        data-imgposition="50% -100%"
      >
        <div className="page-header__bg jarallax-img" />
        {/* /.page-header-bg */}
        <div className="page-header__overlay" />
        {/* /.page-header-overlay */}
        <div className="container text-center">
          <h2 className="page-header__title">FAQs</h2>
          {/* /.page-title */}
          <ul className="page-header__breadcrumb list-unstyled">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <span>FAQS</span>
            </li>
          </ul>
          {/* /.page-breadcrumb list-unstyled */}
        </div>
        {/* /.container */}
      </section>
      {/* /.page-header */}
      {/* Pricing Start */}
      <section className="accrodion-one">
        <div className="container">
          <div className="section-title  text-center">
            <h5 className="section-title__tagline">
              Our Recent FAQS
              <svg
                className="arrow-svg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 55 13"
              >
                <g clipPath="url(#clip0_324_36194)">
                  <path d="M10.5406 6.49995L0.700562 12.1799V8.56995L4.29056 6.49995L0.700562 4.42995V0.819946L10.5406 6.49995Z" />
                  <path d="M25.1706 6.49995L15.3306 12.1799V8.56995L18.9206 6.49995L15.3306 4.42995V0.819946L25.1706 6.49995Z" />
                  <path d="M39.7906 6.49995L29.9506 12.1799V8.56995L33.5406 6.49995L29.9506 4.42995V0.819946L39.7906 6.49995Z" />
                  <path d="M54.4206 6.49995L44.5806 12.1799V8.56995L48.1706 6.49995L44.5806 4.42995V0.819946L54.4206 6.49995Z" />
                </g>
              </svg>
            </h5>
            <h2 className="section-title__title">
              Frequently Asked Question &amp; <br />
              Answers Here
            </h2>
          </div>
          {/* section-title */}
          <div
            className="accrodion-one__wrapper eduact-accrodion"
            data-grp-name="eduact-accrodion"
          >
            <a
              className={active === 1 ? "accrodion active" : "accrodion"}
              onClick={() => {
                setActive(1);
              }}
            >
              <span className="accrodion__icon" />
              <div className="accrodion-title">
                <h4>1. What is speed reading?</h4>
              </div>
              {active === 1 ? (
                <div className="accrodion-content">
                  <div className="inner">
                    <p>
                      Speed reading is the art of consuming information quickly
                      while maintaining comprehension. Imagine reading at three
                      times your usual speed while understanding every word.
                      It's not only about getting through the content quickly;
                      it's also about reading more carefully. They say, "The
                      faster you read, the more you lead." Speed reading helps
                      you to be in control of your learning, improve
                      productivity, and open up fresh possibilities in both your
                      personal and professional lives
                    </p>
                  </div>
                </div>
              ) : null}
            </a>

            {/* /.accordian-item */}
            <a
              className={active === 2 ? "accrodion active" : "accrodion"}
              onClick={() => {
                setActive(2);
              }}
            >
              <span className="accrodion__icon" />
              <div className="accrodion-title">
                <h4>2. Is speed reading possible?</h4>
              </div>
              {/* /.accordian-title */}
              {active === 2 ? (
                <div className="accrodion-content">
                  <div className="inner">
                    <p>
                      Speed reading is entirely possible, and Recall X teaches
                      you the methods used by famous speed readers such as Bill
                      Gates, Ann Jones, and Howard Berg. While it takes regular
                      effort and consistency to uncover something new, Recall X
                      empowers you with the resources and techniques you need to
                      significantly increase your speed of reading while
                      maintaining comprehension.
                    </p>
                  </div>

                  {/* /.accordian-content */}
                </div>
              ) : null}
            </a>
            {/* /.accordian-item */}
            <a
              className={active === 3 ? "accrodion active" : "accrodion"}
              onClick={() => {
                setActive(3);
              }}
            >
              <span className="accrodion__icon" />
              <div className="accrodion-title">
                <h4>3. Is reading just a visual process?</h4>
              </div>
              {/* /.accordian-title */}
              {active === 3 ? (
                <div className="accrodion-content">
                  <div className="inner">
                    <p>
                      Reading is not just visual. Speech is the main method by
                      which humans communicate, therefore it has an unbreakable
                      connection to language. As we read, we are not just
                      observing words; we are changing them into a format that
                      is appropriate for how we understand and utilise language.
                      This is why learning to read takes time and effort, as it
                      demands both visual and verbal processing skills.
                    </p>
                  </div>
                  {/* /.accordian-content */}
                </div>
              ) : null}
            </a>
            {/* /.accordian-item */}
            <a
              className={active === 4 ? "accrodion active" : "accrodion"}
              onClick={() => {
                setActive(4);
              }}
            >
              <span className="accrodion__icon" />
              <div className="accrodion-title">
                <h4>
                  4. What are the professionals who can benefit from speed
                  reading?
                </h4>
              </div>
              {active === 4 ? (
                <div className="accrodion-content">
                  <div className="inner">
                    <p>
                      Speed reading is a significant talent for professionals in
                      an array of areas as it allows them to understand vast
                      volumes of information rapidly and effectively. Executives
                      and managers can use it to assess reports and business
                      documents quicker, while lawyers can speed-read legal
                      agreements and case studies without overlooking crucial
                      information. Researchers, scholars, and journalists can
                      more efficiently consume educational resources and
                      sources, leading to increased output. Speed reading is
                      beneficial to educators and students in terms of properly
                      managing textbooks and academic papers. Consultants,
                      software engineers, and healthcare professionals may
                      quickly examine customer information, technical manuals,
                      and medical publications, which helps make choices under
                      pressure. Entrepreneurs stay on top of industry trends,
                      while financial experts manage reports with less
                      difficulty.
                    </p>
                  </div>
                  {/* /.accordian-content */}
                </div>
              ) : null}
            </a>
            {/* /.accordian-item */}
            <a
              className={active === 5 ? "accrodion active" : "accrodion"}
              onClick={() => {
                setActive(5);
              }}
            >
              <span className="accrodion__icon" />
              <div className="accrodion-title">
                <h4>5. How Long Are the Recall X Sessions?</h4>
              </div>
              {/* /.accordian-title */}
              {active === 5 ? (
                <div className="accrodion-content">
                  <div className="inner">
                    <p>
                      At Recall X, we understand how important time is. That's
                      why each daily session continues for only 20 to 25
                      minutes—short enough to fit into your busy day yet
                      effective enough to make a big effect. As Albert Einstein
                      said, "The measure of intelligence is the ability to
                      change." With just 20 minutes every day, you'll see
                      substantial improvements in your speed of reading and
                      understanding in just 26 days. Recall X may achieve
                      amazing outcomes in just a few dedicated minutes per day!
                    </p>
                  </div>

                  {/* /.accordian-content */}
                </div>
              ) : null}
            </a>

            <a
              className={active === 6 ? "accrodion active" : "accrodion"}
              onClick={() => {
                setActive(6);
              }}
            >
              <span className="accrodion__icon" />
              <div className="accrodion-title">
                <h4>6. What is the Recall X Speed Reading Course?</h4>
              </div>
              {active === 6 ? (
                <div className="accrodion-content">
                  <div className="inner">
                    <p>
                      The Recall X Speed Reading Course is an innovative 26-day
                      program that will improve your reading speed and fluency.
                      Using tried-and-true approaches, you'll discover how to
                      absorb material more quickly while retaining thorough
                      knowledge. Consider this: "Read faster, achieve more."
                      Whether you're a student, a professional with a busy
                      schedule, or an avid learner, Recall X allows you to
                      recall more in fewer seconds, accessing new levels of
                      productivity and concentration
                    </p>
                  </div>
                  {/* /.accordian-content */}
                </div>
              ) : null}
            </a>
            <a
              className={active === 7 ? "accrodion active" : "accrodion"}
              onClick={() => {
                setActive(7);
              }}
            >
              <span className="accrodion__icon" />
              <div className="accrodion-title">
                <h4>7. Do you skip words when you speed-read?</h4>
              </div>
              {active === 7 ? (
                <div className="accrodion-content">
                  <div className="inner">
                    <p>
                      The idea that rapid readers skip words is a myth. On the
                      contrary, they read in clusters, gathering numerous words
                      simultaneously to increase speed and comprehension. Speed
                      reading enhances how the eyes and mind receive
                      information, making sure no critical details are missed.
                      Readers can improve their reading speed without losing
                      understanding or retention by using strategies like those
                      in Recall X.
                    </p>
                  </div>
                  {/* /.accordian-content */}
                </div>
              ) : null}
            </a>
            <a
              className={active === 8 ? "accrodion active" : "accrodion"}
              onClick={() => {
                setActive(8);
              }}
            >
              <span className="accrodion__icon" />
              <div className="accrodion-title">
                <h4>8. Is a Speed Reading Course a Necessity?</h4>
              </div>
              {active === 8 ? (
                <div className="accrodion-content">
                  <div className="inner">
                    <p>
                      In modern times, when information overload is prevalent, a
                      speed reading class can be very helpful. It allows people
                      to process and retain huge amounts of knowledge in less
                      time, making it a vital skill for learners, educators, and
                      anyone trying to enhance their productivity. While not
                      required for everyone, it is important for people who must
                      manage a great deal of reading without losing
                      comprehension.
                    </p>
                  </div>
                  {/* /.accordian-content */}
                </div>
              ) : null}
            </a>
            <a
              className={active === 9 ? "accrodion active" : "accrodion"}
              onClick={() => {
                setActive(9);
              }}
            >
              <span className="accrodion__icon" />
              <div className="accrodion-title">
                <h4>
                  9. How Do Different Age Groups Benefit from Speed Reading?
                </h4>
              </div>
              {active === 9 ? (
                <div className="accrodion-content">
                  <div className="inner">
                    <p>
                      Speed reading provides unique benefits to various age
                      groups. It improves student performance in school by
                      helping them to learn more information in less time. Early
                      Career Professionals can boost their productivity by
                      rapidly acquiring information at work. For seniors, fast
                      reading improves cognitive agility and memory retention,
                      providing long-term mental benefits.
                    </p>
                  </div>
                  {/* /.accordian-content */}
                </div>
              ) : null}
            </a>
            <a
              className={active === 9 ? "accrodion active" : "accrodion"}
              onClick={() => {
                setActive(10);
              }}
            >
              <span className="accrodion__icon" />
              <div className="accrodion-title">
                <h4>
                  10. Why Are Fewer People Aware of Speed Reading Courses?
                </h4>
              </div>
              {active === 10 ? (
                <div className="accrodion-content">
                  <div className="inner">
                    <p>
                      Many people are unaware of speed reading courses because
                      of a lack of widespread knowledge and a notion that
                      reading quicker equals giving up understanding.
                      Furthermore, traditional education systems place more
                      value on core reading skills than on advanced approaches
                      such as speed reading, leaving many people unaware of the
                      potential benefits.
                    </p>
                  </div>
                </div>
              ) : null}
            </a>
            <a
              className={active === 11 ? "accrodion active" : "accrodion"}
              onClick={() => {
                setActive(11);
              }}
            >
              <span className="accrodion__icon" />
              <div className="accrodion-title">
                <h4>
                  11. Is speed reading highly beneficial for older adults?
                </h4>
              </div>
              {active === 11 ? (
                <div className="accrodion-content">
                  <div className="inner">
                    <p>
                      Yes, speed reading can be extremely helpful to seniors. It
                      helps people retain cognitive agility by enhancing their
                      reading speed and understanding. People's logical speeds
                      could decrease as they age, but speed reading practices
                      could assist them in preserving mental sharpness and
                      memory. Speed reading, a technique that allows for faster
                      information absorption, additionally assists mature
                      learners in staying interested in reading materials while
                      keeping up with various hobbies or necessary reading
                      duties.
                    </p>
                  </div>
                  {/* /.accordian-content */}
                </div>
              ) : null}
            </a>
            <a
              className={active === 12 ? "accrodion active" : "accrodion"}
              onClick={() => {
                setActive(12);
              }}
            >
              <span className="accrodion__icon" />
              <div className="accrodion-title">
                <h4>
                  12. I heard it takes too much time to learn speed reading. Is
                  that true for the Recall X course?
                </h4>
              </div>
              {active === 12 ? (
                <div className="accrodion-content">
                  <div className="inner">
                    <p>
                      It is a frequent misunderstanding that getting speed
                      reading requires a lot of time. The Recall X Speed Reading
                      Course is meant to be completed in just 26 days, giving
                      students a concentrated and efficient way to master speed
                      reading techniques. The time frame is carefully planned to
                      allow for steady skill improvement, regular practice,
                      along suitable feedback, ensuring that those participating
                      can improve their speed of reading and comprehension
                      without making a long-term commitment.
                    </p>
                  </div>
                </div>
              ) : null}
            </a>
            <a
              className={active === 13 ? "accrodion active" : "accrodion"}
              onClick={() => {
                setActive(13);
              }}
            >
              <span className="accrodion__icon" />
              <div className="accrodion-title">
                <h4>
                  13. Can people really understand what they read at over 500
                  words per minute?
                </h4>
              </div>
              {active === 13 ? (
                <div className="accrodion-content">
                  <div className="inner">
                    <p>
                      Reading at exceptionally fast speeds, such as over 500
                      words per minute, can raise comprehension questions. True
                      efficiency achieves a balance between speed and
                      understanding. The Recall X Speed Reading Course enhances
                      your readability and understanding, ensuring that you
                      retain and comprehend crucial details when reading faster.
                    </p>
                  </div>
                </div>
              ) : null}
            </a>
            <a
              className={active === 14 ? "accrodion active" : "accrodion"}
              onClick={() => {
                setActive(14);
              }}
            >
              <span className="accrodion__icon" />
              <div className="accrodion-title">
                <h4>
                  14. Are speed reading techniques transferable to other
                  languages?
                </h4>
              </div>
              {active === 14 ? (
                <div className="accrodion-content">
                  <div className="inner">
                    <p>
                      Yes, speed reading techniques are appropriate for various
                      languages. While particular techniques may require minor
                      modifications to account for variations in linguistic
                      structures, the basic principles of speed reading—such as
                      reading in clusters and boosting comprehension—apply all
                      over languages. The Recall X Speed Reading Course will
                      assist you apply these strategies to improve your reading
                      efficiency across multiple languages.
                    </p>
                  </div>
                  {/* /.accordian-content */}
                </div>
              ) : null}
            </a>
            <a
              className={active === 15 ? "accrodion active" : "accrodion"}
              onClick={() => {
                setActive(15);
              }}
            >
              <span className="accrodion__icon" />
              <div className="accrodion-title">
                <h4>
                  15. What is the process to enrol in the Recall X Speed Reading
                  Course?
                </h4>
              </div>
              {active === 15 ? (
                <div className="accrodion-content">
                  <div className="inner">
                    <p>
                      Enrollment in the Recall X Speed Reading Course is easy.
                      First, fill out the course registration form. Next, make a
                      payment and complete your enrollment. Following purchase,
                      you will get an email including a link with your login
                      information, including your ID and password. Once you've
                      your login, you can access the course materials and begin
                      your speed reading journey. You will have this access
                      lifetime.
                    </p>
                  </div>
                  {/* /.accordian-content */}
                </div>
              ) : null}
            </a>
            <a
              className={active === 16 ? "accrodion active" : "accrodion"}
              onClick={() => {
                setActive(16);
              }}
            >
              <span className="accrodion__icon" />
              <div className="accrodion-title">
                <h4>
                  16. What are the three main pillars of the Recall X Speed
                  Reading Course?
                </h4>
              </div>
              {active === 16 ? (
                <div className="accrodion-content">
                  <div className="inner">
                    <p>
                      The Recall X Speed Reading Course revolves around three
                      main pillars. The first pillar, Speed with Comprehension,
                      discusses ways to improve reading speed while preserving
                      retention and comprehension of data. The second pillar,
                      Note-taking and Note-making, offers efficient ways for
                      gaining and organizing information, thus increasing both
                      learning and recall. The third pillar, How to Remember
                      Complicated Subjects with Ease, provides ways and
                      materials for simplifying and retaining complicated
                      themes, making challenging data more manageable. Together,
                      these pillars provide a complete method for mastering fast
                      reading and increasing overall learning efficiency.
                    </p>
                  </div>
                  {/* /.accordian-content */}
                </div>
              ) : null}
            </a>
            <a
              className={active === 17 ? "accrodion active" : "accrodion"}
              onClick={() => {
                setActive(17);
              }}
            >
              <span className="accrodion__icon" />
              <div className="accrodion-title">
                <h4>
                  17. What can I expect in terms of progress and results by the
                  end of the 26-day course?
                </h4>
              </div>
              {active === 17 ? (
                <div className="accrodion-content">
                  <div className="inner">
                    <p>
                      By the end of the 26-day Recall X Speed Reading Course,
                      you may expect to substantially boost your reading speed
                      while maintaining understanding. You will also improve
                      your note-taking and organization skills, and also master
                      ways for memorizing complex topics. Overall, you'll see
                      substantial improvements in both reading efficiency and
                      retention, laying the groundwork for further development
                      in your reading abilities.
                    </p>
                  </div>
                  {/* /.accordian-content */}
                </div>
              ) : null}
            </a>

            <a
              className={active === 18 ? "accrodion active" : "accrodion"}
              onClick={() => {
                setActive(18);
              }}
            >
              <span className="accrodion__icon" />
              <div className="accrodion-title">
                <h4>
                  18. Are there any prerequisites or required skills before
                  starting the Recall X course?
                </h4>
              </div>
              {active === 18 ? (
                <div className="accrodion-content">
                  <div className="inner">
                    <p>
                      There are no special prerequisites or skills required
                      before commencing the Recall X Speed Reading Course. The
                      course was created to accommodate students of various
                      skill levels, from beginners to proficient readers. All
                      you need is a minimal reading ability and a desire to
                      practice and develop. The course will give you the
                      necessary abilities and tools to enhance your reading
                      speed and understanding from the bottom up.
                    </p>
                  </div>
                  {/* /.accordian-content */}
                </div>
              ) : null}
            </a>

            {/* /.accordian-item */}
          </div>
          {/* accrodion-one */}
          {/* accrodion-one */}
        </div>
      </section>
      {/* Pricing Start */}
      {/* CTA Start */}
      <section className="cta-faq">
        <div className="container">
          <div className="cta-faq__help text-center">
            <div
              className="cta-faq__help__bg"
              style={{
                backgroundImage: "url(assets/images/backgrounds/faq-cta.jpg)",
              }}
            />
            <div className="cta-faq__help__icon">
              <span className="fas fa-envelope" />
            </div>
            <h3 className="cta-faq__help__title" style={{ lineHeight: 1.5 }}>
              If you still have questions about speed reading that have not been
              answered
            </h3>
            <div className="cta-faq__help__border" />
            <p className="cta-faq__help__text">
              Drop us an <a href="mailto:info@recallx.in">info@recallx.in</a>
            </p>

            <Link
              to="/contact-us"
              className="eduact-btn eduact-btn-second mt-4"
            >
              Inquiry
              <i className="icon-arrow" />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};
