import React from "react";
import { NavLink } from "react-router-dom";

const MobileMenu = ({ active, onClose }) => {
  return (
    <div
      className={
        active ? "mobile-nav__wrapper expanded" : "mobile-nav__wrapper"
      }
    >
      <div className="mobile-nav__overlay mobile-nav__toggler" />
      {/* /.mobile-nav__overlay */}
      <div className="mobile-nav__content">
        <span
          className="mobile-nav__close mobile-nav__toggler"
          onClick={onClose}
        >
          <i className="fa fa-times" />
        </span>
        <div className="logo-box">
          <a href="/" aria-label="logo image">
            <img src="assets/images/white-logo.png" width={183} alt="recallx" />
          </a>
        </div>
        {/* /.logo-box */}
        <div className="mobile-nav__container" />
        <ul className="main-menu__list">
          <li>
            <NavLink to="/" onClick={onClose}>
              Home
            </NavLink>
          </li>
          <li className="current">
            <NavLink to="/course" onClick={onClose}>
              Course
            </NavLink>
          </li>
          <li className="current">
            <NavLink to="/checkout" onClick={onClose}>
              Buy Course
            </NavLink>
          </li>
          <li>
            <NavLink to="/blogs" onClick={onClose}>
              Blog
            </NavLink>
          </li>
          <li>
            <NavLink to="/faq" onClick={onClose}>
              FAQ
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact-us" onClick={onClose}>
              Contact
            </NavLink>
          </li>
        </ul>
        {/* /.mobile-nav__container */}
        <ul className="mobile-nav__contact list-unstyled">
          <li>
            <i className="fas fa-envelope" />
            <a href="mailto:info@recallx.in">info@recallx.in</a>
          </li>
          <li>
            <i className="fa fa-phone-alt" />
            <a href="tel:+919879814480">(+91) 98798-14480</a>
          </li>
        </ul>
        {/* /.mobile-nav__contact */}
        <div className="mobile-nav__social">
          <a href="https://www.facebook.com/profile.php?id=100086323626031">
            <i className="fab fa-facebook" />
          </a>
          <a href="https://www.linkedin.com/company/85672915/admin/dashboard/">
            <i className="fab fa-linkedin-in" />
          </a>
          <a href="https://www.instagram.com/recallx.in?igsh=MWNlOHp6OXM0YWlzdQ==">
            <i className="fab fa-instagram" />
          </a>
          <a href="https://g.page/r/Cf6ly52rEGSyEBM/review">
            <i className="fab fa-google"></i>
          </a>
          <a href="https://www.youtube.com/">
            <i className="fab fa-youtube" />
          </a>
        </div>
        {/* /.mobile-nav__social */}
      </div>
      {/* /.mobile-nav__content */}
    </div>
  );
};

export default MobileMenu;
