import React from "react";
import { NavLink } from "react-router-dom";
const Header = ({ mmOpen }) => {
  const phoneNumber = "+9879814480";
  return (
    <>
      <a
        href="tel:+919879814480"
        className="d-lg-none d-s-block"
        style={{
          background: "#0f1083",
          fontSize: 16,
          textAlign: "center",
          paddingTop: 4,
          paddingBottom: 4,
          fontWeight: "bold",
          color: "#FFF",
          width: "100%",
          position: "fixed",
          top: 0,
          zIndex: 9999,
        }}
      >
        Call Now : (+91) 98798-14480
      </a>

      <header className="main-header">
        <nav className="main-menu main-menu-with-bg">
          <div className="container">
            <div className="main-menu__logo">
              <a href="/">
                <img
                  src="assets/images/recallx-logo.png"
                  height={80}
                  alt="Eduact"
                />
              </a>
            </div>
            {/* /.main-menu__logo */}
            <div className="main-menu__nav">
              <ul className="main-menu__list">
                <li className="current">
                  <NavLink to="/">Home</NavLink>
                </li>
                <li>
                  <NavLink to="/about">About Us</NavLink>
                </li>
                <li>
                  <NavLink to="/course">Course</NavLink>
                </li>
                <li>
                  <NavLink to="/checkout">Buy Course</NavLink>
                </li>
                <li>
                  <NavLink to="/faq">FAQ</NavLink>
                </li>
                <li>
                  <NavLink to="/contact-us">Blog</NavLink>
                </li>
                <li>
                  <NavLink to="/contact-us">Contact</NavLink>
                </li>
              </ul>
            </div>
            {/* /.main-menu__nav */}
            <div className="main-menu__right">
              <a
                onClick={mmOpen}
                className="main-menu__toggler mobile-nav__toggler"
              >
                <i className="fa fa-bars" />
              </a>
              <div>
                <a href={`tel:${phoneNumber}`} className="eduact-btn">
                  <span className="eduact-btn__curve" />
                  Click to call (+91 9879814480)
                </a>
                {/* <p
                className="mt-2"
                style={{ fontWeight: "bold", color: "#0f118c" }}
              >
                +91 98798-14480
              </p> */}
              </div>
              {/* /.contact btn */}
            </div>
            {/* /.main-menu__right */}
          </div>
          {/* /.container */}
        </nav>
        {/* /.main-menu */}
      </header>
    </>
  );
};

export default Header;
