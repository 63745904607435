import { memo } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";

const MainCarousel = () => {
  return (
    <OwlCarousel
      className="main-slider__one owl-carousel"
      loop={true}
      items={1}
      smartSpeed={1000}
      autoplay={false}
      autoplayTimeout={7000}
      autoplayHoverPause={true}
      nav={false}
      dots={true}
      margin={0}
    >
      <div className="item">
        <div className="main-slider__item" style={{ border: "none" }}>
          <div
            className="main-slider__bg"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/shapes/slider-bg-1.jpg)`,
            }}
          />
          <div className="main-slider__shape-one">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/shapes/slider-shape-1.png`}
              alt="slider shape"
            />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="main-slider__content">
                  <h2 className="main-slider__title mt-4">
                    Let’s <br /> change your reading game
                    <br /> with <span style={{ fontSize: 65 }}>Recall X</span>
                    <p style={{ fontSize: 25 }}>
                      The Recall X Speed Reading Course,
                    </p>
                  </h2>
                  <p className="main-slider__text">
                    Transform Your Reading Skills in Just <b>26 Days</b>!
                  </p>
                  <div className="main-slider__btn">
                    <Link to="/course" className="eduact-btn eduact-btn-second">
                      Find the Course
                      <i className="icon-arrow" />
                    </Link>
                    <Link
                      to="/checkout"
                      className="eduact-btn eduact-btn-second"
                      style={{ background: "#0f1083", marginLeft: 15 }}
                    >
                      Buy Course Today
                      <i className="icon-arrow" />
                    </Link>
                    {/* <Link
                      to="/videos"
                      style={{ marginLeft: 15 }}
                      className="eduact-btn eduact-btn-second"
                    >
                      Course Intro
                      <i className="fas fa-play"></i>
                    </Link> */}
                  </div>
                  {/* <div className="main-slider__btn mt-3">
                    <Link
                      to="/course"
                      className="eduact-btn eduact-btn-second"
                      style={{ background: "#0f1083" }}
                    >
                      Start Your Course
                      <i className="icon-arrow" />
                    </Link>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="main-slider__layer ">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/main-img.png`}
                    alt="slider layer"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </OwlCarousel>
  );
};

export default memo(MainCarousel);
