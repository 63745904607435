import React, { useState, useRef } from "react";
import { Helmet } from "react-helmet";
import YouTube from "react-youtube";

export default function Videos() {
  const mainScreenRef = useRef(null); // Reference to the main screen YouTube player
  const subScreenRef = useRef(null); // Reference to the sub screen YouTube player

  // YouTube player options
  const opts = {
    height: "600",
    width: "100%",
    playerVars: {
      autoplay: 1, // Don't autoplay
      controls: 1, // Show player controls
    },
  };

  // Handle sub-screen click
  const handleSubScreenClick = () => {
    const mainPlayer = mainScreenRef.current.getInternalPlayer();
    const subPlayer = subScreenRef.current.getInternalPlayer();

    if (mainPlayer && subPlayer) {
      // Pause the sub-screen video if it's playing
      subPlayer.pauseVideo();

      // Play the video on the main-screen
      mainPlayer.playVideo();

      // Optionally, trigger fullscreen for the main-screen
      const mainIframe = mainPlayer.getIframe();
      if (mainIframe.requestFullscreen) {
        mainIframe.requestFullscreen();
      } else if (mainIframe.mozRequestFullScreen) {
        mainIframe.mozRequestFullScreen(); // Firefox
      } else if (mainIframe.webkitRequestFullscreen) {
        mainIframe.webkitRequestFullscreen(); // Chrome, Safari, Opera
      } else if (mainIframe.msRequestFullscreen) {
        mainIframe.msRequestFullscreen(); // IE/Edge
      }
    }
  };

  return (
    <div>
      <Helmet>
        <title>Blogs | RecallX</title>
      </Helmet>

      <div
        className="stricky-header stricked-menu main-menu main-menu-with-bg"
        style={{ background: "#EFEFF3" }}
      ></div>

      <section className="blog-page">
        <div className="container">
          {/* Main Screen */}
          <YouTube
            ref={mainScreenRef}
            videoId="Azh8OkHQFNY"
            style={{ width: "100%" }}
            opts={opts}
            autoplay={true}
            onReady={(event) => {
              console.log("Main Player Ready");
            }}
          />

          {/* Sub Screen */}
          {/* <div className="row mt-4">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <YouTube
                  ref={subScreenRef}
                  videoId="5Hnl3NZZufg"
                  style={{ borderRadius: 10 }}
                  opts={{
                    height: "250",
                    width: "100%",
                    playerVars: {
                      autoplay: 0, // No autoplay for sub-screen
                      controls: 0, // Hide player controls
                    },
                  }}
                  onReady={(event) => {
                    console.log("Sub Player Ready");
                  }}
                  onPlay={() => handleSubScreenClick()} // Intercept play event for sub-screen
                />
              </div>
            </div> */}
        </div>
      </section>
    </div>
  );
}
