import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function NotFound() {
  return (
    <div>
      <Helmet>
        <title>404 Not Found | RecallX</title>
      </Helmet>
      {/* /.main-header */}
      <div
        className="stricky-header stricked-menu main-menu main-menu-with-bg"
        style={{ background: "#EFEFF3" }}
      ></div>

      <section className="error-page">
        <div className="container">
          <div className="error-page__content">
            {/*<h1 class="error-page__404-title">404</h1>*/}
            <div className="error-page__thumb">
              <div className="error-page__404">
                <img src="assets/images/shapes/404.png" alt="eduact" />
              </div>
              {/* 404-image */}
              <div
                className="error-page__shape1 wow zoomIn"
                data-wow-delay="300ms"
              >
                <img src="assets/images/shapes/404-shape-1.png" alt="eduact" />
              </div>
              {/* 404-shape-01 */}
              <div
                className="error-page__shape2 wow zoomIn"
                data-wow-delay="400ms"
              >
                <img src="assets/images/shapes/404-shape-2.png" alt="eduact" />
              </div>
              {/* 404-shape-02 */}
              <div className="error-page__shape3">
                <img src="assets/images/shapes/404-shape-3.png" alt="eduact" />
              </div>
              {/* 404-shape-03 */}
              <div className="error-page__shape4">
                <img src="assets/images/shapes/404-shape-4.png" alt="eduact" />
              </div>
              {/* 404-shape-04 */}
              <div className="error-page__shape5">
                <img src="assets/images/shapes/404-shape-5.png" alt="eduact" />
              </div>
              {/* 404-shape-05 */}
              <div className="error-page__shape6">
                <img src="assets/images/shapes/404-shape-6.png" alt="eduact" />
              </div>
              {/* 404-shape-06 */}
              <div className="error-page__shape7">
                <img src="assets/images/shapes/404-shape-7.png" alt="eduact" />
              </div>
              {/* 404-shape-07 */}
              <div className="error-page__shape8">
                <img src="assets/images/shapes/404-shape-8.png" alt="eduact" />
              </div>
              {/* 404-shape-08 */}
              <div className="error-page__shape9">
                <img src="assets/images/shapes/404-shape-9.png" alt="eduact" />
              </div>
              {/* 404-shape-09 */}
              <div className="error-page__shape10">
                <img src="assets/images/shapes/404-shape-10.png" alt="eduact" />
              </div>
              {/* 404-shape-10 */}
              <div className="error-page__shape11">
                <img src="assets/images/shapes/404-shape-11.png" alt="eduact" />
              </div>
              {/* 404-shape-11 */}
            </div>
            <h4 className="error-page__title">Oops! Page not Found</h4>
            {/* 404-title */}
            <p className="error-page__text">
              The page you are looking for is not exist.
            </p>
            {/* 404-content */}

            {/* 404-search-form */}
            <Link to="/" className="eduact-btn eduact-btn-second">
              <span className="eduact-btn__curve" />
              Back to Home
              <i className="icon-arrow" />
            </Link>
            {/* 404-btn */}
          </div>
          {/* 404-info */}
        </div>
      </section>
    </div>
  );
}
