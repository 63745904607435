import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const BlogBox = (props) => {
  return (
    <div className="col-xl-4 col-md-6 wow fadeInUp" data-wow-delay="300ms">
      <div className="blog-two__item">
        <div className="blog-two__image">
          <img src={props.img_url} alt={props.link_tag} />
          <Link to={`/blog/${props.link_tag}`}></Link>
        </div>

        <div className="blog-two__content">
          <div className="blog-two__top-meta">
            <div className="blog-two__cats">
              <Link to={`/blog/${props.link_tag}`}>New</Link>
            </div>

            <div className="blog-two__date">{props.date}</div>
          </div>
          <h3 className="blog-two__title">
            <Link to={`/blog/${props.link_tag}`}>{props.title}</Link>
          </h3>

          <div className="blog-two__meta">
            <div className="blog-two__meta__author">
              <img src="assets/images/nilay-doshi.png" alt="eduact" />
              <Link to="/about">Mr. Nilay Doshi</Link>
              Founder
            </div>
            <Link to={`/blog/${props.link_tag}`} className="blog-two__rm">
              <span className="icon-arrow" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function BlogList() {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const formData = new FormData();
    formData.append("API_KEY", "RecallX84014");
    formData.append("op", "blogList");

    fetch("https://api.recallx.in/blog.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error_code === 0) {
          setData(data.blog);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <div>
      <Helmet>
        <title>Blogs | RecallX</title>
      </Helmet>

      {/* /.main-header */}
      <div
        className="stricky-header stricked-menu main-menu main-menu-with-bg"
        style={{ background: "#EFEFF3" }}
      ></div>

      <section
        className="page-header page-header--bg-two"
        data-jarallax
        data-speed="0.3"
        data-imgposition="50% -100%"
      >
        <div className="page-header__bg jarallax-img" />
        {/* /.page-header-bg */}
        <div className="page-header__overlay" />
        {/* /.page-header-overlay */}
        <div className="container text-center">
          <h2 className="page-header__title">Blog</h2>
          {/* /.page-title */}
          <ul className="page-header__breadcrumb list-unstyled">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <span>Blog</span>
            </li>
          </ul>
        </div>
      </section>

      <section className="blog-page">
        <div className="container">
          <div className="row">
            {data.map((data, index) => {
              return (
                <BlogBox
                  title={data.title}
                  img_url={data.img}
                  link_tag={data.link_tag}
                  date={data.date}
                  key={data.id}
                />
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
}
