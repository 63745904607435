import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  FacebookShare,
  LinkedinShare,
  WhatsappShare,
  EmailShare,
} from "react-share-kit";
import LatestBlog from "./LatestBlog";
import { Helmet } from "react-helmet";

export default function BlogView() {
  const [mobileMenu, setMMV] = useState(false);
  const [data, setData] = useState({});
  const [latestBlogData, setLBData] = useState([]);
  const [tags, setTags] = useState([]);
  const [currentUrl, setURL] = useState();
  const { linkTag } = useParams();

  useEffect(() => {
    setURL(window.location.href);
    fetchData();
    latestBlog();
  }, [linkTag]); // Added linkTag as a dependency

  const fetchData = () => {
    const formData = new FormData();
    formData.append("API_KEY", "RecallX84014");
    formData.append("op", "blogContent");
    formData.append("link_tag", linkTag);

    fetch("https://api.recallx.in/blog.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error_code === 0) {
          setData(data.blog);
          setTags(data.blog.tag || []); // Handle undefined or null tag
        } else {
          setData({});
          setTags([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const latestBlog = () => {
    const formData = new FormData();
    formData.append("API_KEY", "RecallX84014");
    formData.append("op", "blogList");

    fetch("https://api.recallx.in/blog.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error_code === 0) {
          setLBData(data.blog);
        } else {
          setLBData([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching latest blogs:", error);
      });
  };

  return (
    <div>
      <Helmet>
        <title>
          {data.title ? `${data.title} | RecallX` : "Blogs | RecallX"}
        </title>
      </Helmet>

      <div
        className="stricky-header stricked-menu main-menu main-menu-with-bg"
        style={{ background: "#EFEFF3" }}
      ></div>

      <section
        className="page-header page-header--bg-two"
        data-jarallax
        data-speed="0.3"
        data-imgposition="50% -100%"
      >
        <div className="page-header__bg jarallax-img" />
        <div className="page-header__overlay" />
        <div className="container text-center">
          <h2 className="page-header__title">Blog</h2>
          <ul className="page-header__breadcrumb list-unstyled">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <span>Blog</span>
            </li>
          </ul>
        </div>
      </section>

      <section className="blog-details">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-8">
              <div className="blog-details__content">
                <div className="blog-details__img">
                  <img src={data.img} alt="Blog" />
                </div>
                <div className="blog-details__meta">
                  <div className="blog-details__meta__date">
                    <i className="icon-clock" />
                    {data.date}
                  </div>
                </div>
                <h3 className="blog-details__title">{data.title}</h3>
                <p className="blog-details__text">{data.content}</p>
              </div>
              <div className="blog-details__bottom">
                <div className="blog-details__tags">
                  <h5 className="blog-details__tags__title">Tags</h5>
                  {tags.map((tag, index) => (
                    <a href={`/tags/${tag}`} key={index}>
                      {tag}
                    </a>
                  ))}
                </div>
                <div className="blog-details__social">
                  <FacebookShare
                    url={currentUrl}
                    title={data.title}
                    quote={data.content} // Changed to content
                    round={true}
                    size={45}
                    style={{ marginRight: 4 }}
                  />
                  <LinkedinShare
                    url={currentUrl}
                    title={data.title}
                    quote={data.content} // Changed to content
                    round={true}
                    size={45}
                    style={{ marginRight: 4 }}
                  />
                  <WhatsappShare
                    url={currentUrl}
                    title={data.title}
                    quote={data.content} // Changed to content
                    round={true}
                    size={45}
                    style={{ marginRight: 4 }}
                  />
                  <EmailShare
                    url={currentUrl}
                    title={data.title}
                    quote={data.content} // Changed to content
                    round={true}
                    size={45}
                  />
                </div>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-5 wow fadeInRight"
              data-wow-delay="300ms"
            >
              <div className="sidebar">
                <div className="sidebar__single sidebar__category">
                  <h3 className="sidebar__title">Share On</h3>
                  <div className="mt-3 mb-3">
                    <FacebookShare
                      url={currentUrl}
                      title={data.title}
                      quote={data.content} // Changed to content
                      round={true}
                      size={45}
                      style={{ marginRight: 4 }}
                    />
                    <LinkedinShare
                      url={currentUrl}
                      title={data.title}
                      quote={data.content} // Changed to content
                      round={true}
                      size={45}
                      style={{ marginRight: 4 }}
                    />
                    <WhatsappShare
                      url={currentUrl}
                      title={data.title}
                      quote={data.content} // Changed to content
                      round={true}
                      size={45}
                      style={{ marginRight: 4 }}
                    />
                    <EmailShare
                      url={currentUrl}
                      title={data.title}
                      quote={data.content} // Changed to content
                      round={true}
                      size={45}
                    />
                  </div>
                </div>
                <div className="sidebar__single sidebar__post">
                  <h3 className="sidebar__title">Latest posts</h3>
                  <ul className="sidebar__post__list list-unstyled">
                    {latestBlogData.map((latestData, index) => (
                      <li key={index}>
                        <div className="sidebar__post__image">
                          <img src={latestData.img} alt={latestData.link_tag} />
                        </div>
                        <div className="sidebar__post__content">
                          <span className="sidebar__post__content__meta">
                            <i className="icon-clock" />
                            {latestData.date}
                          </span>
                          <h3 className="sidebar__post__content__title">
                            <Link to={`/blog/${latestData.link_tag}`}>
                              {latestData.title}
                            </Link>
                          </h3>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="sidebar__single sidebar__tags">
                  <h3 className="sidebar__title">Tags</h3>
                  <div className="sidebar__tags-list">
                    {tags.map((tag, index) => (
                      <a href={`/tags/${tag}`} key={index}>
                        {tag}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
