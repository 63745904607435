import React, { useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from "./Home";
import ScrollToTop from "./ScrollToTop";
import { ContactUs } from "./Contact";
import AnimatedCursor from "react-animated-cursor";
import MobileMenu from "./MobileMenu";
import Header from "./Header";
import Footer from "./Footer";
import { About } from "./About";
import Course from "./Course";
import BlogList from "./BlogList";
import PrivacyPolicy from "./PrivacyPolicy";
import BlogView from "./BlogView";
import { FAQ } from "./FAQ";
import NotFound from "./NotFound";
import Videos from "./Videos";
import BuyNow from "./BuyNow";
import { PageHeader } from "./PageHeader";
import { TestimonialHome } from "./TestimonialHome";
import { Testimonial } from "./Testimonial";

function AppContent() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const location = useLocation(); // Use inside AppContent which is wrapped by BrowserRouter

  return (
    <div className="custom-cursor">
      <AnimatedCursor
        innerSize={8}
        outerSize={23}
        outerAlpha={0.1}
        innerScale={0.7}
        outerScale={2}
        showSystemCursor={true}
        outerStyle={{
          border: "solid 1px #100f88",
        }}
        innerStyle={{ backgroundColor: "rgba(16,15,136,0.4)" }}
        clickables={[
          "a",
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          "label[for]",
          "select",
          "textarea",
          "button",
          ".link",
        ]}
      />
      <div className="page-wrapper">
        {location.pathname === "/" ? (
          <Header
            mmOpen={() => {
              setMobileMenu(true);
            }}
          />
        ) : (
          <PageHeader
            mmOpen={() => {
              setMobileMenu(true);
            }}
          />
        )}

        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="course" element={<Course />} />
          <Route path="blogs" element={<BlogList />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="checkout" element={<BuyNow />} />
          <Route path="testimonial" element={<Testimonial />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="blog/:linkTag" element={<BlogView />} />
          <Route path="videos" element={<Videos />} />
          <Route path="*" element={<NotFound />} />
        </Routes>

        <Footer />
      </div>
      <MobileMenu
        active={mobileMenu}
        onClose={() => {
          setMobileMenu(false);
        }}
      />
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

export default App;
