import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer className="main-footer-two">
        <div className="main-footer-two__bg" />
        <div className="container">
          <div className="row">
            <div
              className="col-xl-4 col-md-5 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="main-footer-two__about">
                <Link to="/" className="main-footer-two__logo">
                  <img
                    src="assets/images/recallx-logo.png"
                    alt="Recall X Logo"
                    height={80}
                  />
                </Link>
                <ul className="main-footer-two__info-list">
                  <li>
                    <span
                      className="icon-Location"
                      style={{ color: "#0f1083" }}
                    />
                    601, Mondeal Heights, Sarkhej - Gandhinagar Hwy, nr. Novotel
                    Hotel, Ramdev Nagar, Ahmedabad, Gujarat 380015
                  </li>
                  <li>
                    <span
                      className="icon-Telephone"
                      style={{ color: "#0f1083" }}
                    />
                    <a href="tel:+919879814480">(+91) 98798-14480</a>
                  </li>
                  <li>
                    <span className="icon-Email" style={{ color: "#0f1083" }} />
                    <a href="mailto:info@recallx.in">info@recallx.in</a>
                  </li>
                </ul>
                <div className="main-footer-two__social">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/profile.php?id=100086323626031"
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/company/85672915/admin/dashboard/"
                  >
                    <i className="fab fa-linkedin-in" />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/recallx.in?igsh=MWNlOHp6OXM0YWlzdQ=="
                  >
                    <i className="fab fa-instagram" />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://g.page/r/Cf6ly52rEGSyEBM/review"
                  >
                    <i className="fab fa-google" />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.youtube.com/watch?v=Azh8OkHQFNY&t=1s"
                  >
                    <i className="fab fa-youtube" />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-xl-3 col-md-4 wow fadeInUp"
              data-wow-delay="200ms"
            >
              <div className="main-footer-two__navmenu main-footer-two__widget01">
                <h3
                  className="main-footer-two__title"
                  style={{ color: "#0f1083" }}
                >
                  Quick Links
                </h3>
                <ul style={{ color: "#0f1083" }}>
                  <li>
                    <Link to="/course">About Course</Link>
                  </li>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/blogs">Blogs & News</Link>
                  </li>
                  <li>
                    <Link to="/faq">FAQ</Link>
                  </li>
                  <li>
                    <Link to="/testimonial">Testimonial</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xl-2 col-md-3 wow fadeInUp"
              data-wow-delay="300ms"
            >
              <div className="main-footer-two__navmenu main-footer-two__widget02">
                <h3
                  className="main-footer-two__title"
                  style={{ color: "#0f1083" }}
                >
                  Explore
                </h3>
                <ul style={{ color: "#0f1083" }}>
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xl-3 col-md-12 wow fadeInUp"
              data-wow-delay="300ms"
            >
              <div className="main-footer-two__navmenu main-footer-two__widget02">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.018879730157!2d72.5067845!3d23.023079!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9bc0acaff575%3A0xb26410ab9dcba5fe!2sRecall%20X!5e0!3m2!1sen!2sin!4v1726678714850!5m2!1sen!2sin"
                  style={{ border: 0 }}
                  allowFullScreen
                  title="Recall X Location"
                />
                <a
                  href="//www.dmca.com/Protection/Status.aspx?ID=f1c562be-96d6-4960-8c9f-9241ec7eb30c"
                  title="DMCA.com Protection Status"
                  className="dmca-badge"
                >
                  <img
                    src="https://images.dmca.com/Badges/dmca-badge-w100-5x1-07.png?ID=f1c562be-96d6-4960-8c9f-9241ec7eb30c"
                    alt="DMCA.com Protection Status"
                    style={{ width: 100 }}
                  />
                </a>
                <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"></script>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <section className="copyright text-center">
        <div className="container wow fadeInUp" data-wow-delay="400ms">
          <p className="copyright__text">
            Copyright &copy; 2024 | RecallX.in. All Rights Reserved
          </p>
        </div>
      </section>
    </>
  );
}
