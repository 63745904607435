import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSub] = useState("");
  const [phone, setPhone] = useState("");
  const [msg, setMsg] = useState("");
  const [send, setSend] = useState(false);
  const [error, setErr] = useState("");

  const submit = () => {
    // Reset error message
    setErr("");

    if (name.length === 0) {
      setErr("Please enter your name");
    } else if (email.length === 0) {
      setErr("Please enter your email-id");
    } else if (subject.length === 0) {
      setErr("Please enter subject of message");
    } else if (phone.length === 0) {
      setErr("Please enter your phone number");
    } else if (msg.length === 0) {
      setErr("Please type your message");
    } else {
      const formData = new FormData();
      formData.append("API_KEY", "RecallX84014");
      formData.append("op", "contact");
      formData.append("name", name);
      formData.append("email", email);
      formData.append("subject", subject);
      formData.append("phone", phone);
      formData.append("msg", msg);

      fetch("https://api.recallx.in/blog.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
          if (data.error_code === 0) {
            setSend(true);
          } else {
            setSend(false);
            setErr(data.message || "An error occurred, please try again.");
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setErr("An error occurred while submitting your message.");
        });
    }
  };

  return (
    <div>
      <Helmet>
        <title>Contact Us | RecallX</title>
      </Helmet>

      <div className="stricky-header stricked-menu main-menu main-header-two">
        <div className="sticky-header__content" />
      </div>

      <section
        className="page-header page-header--bg-two"
        data-jarallax
        data-speed="0.3"
        data-imgposition="50% -100%"
      >
        <div className="page-header__bg jarallax-img" />
        <div className="page-header__overlay" />
        <div className="container text-center">
          <h2 className="page-header__title">Contact</h2>
          <ul className="page-header__breadcrumb list-unstyled">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <span>Contact</span>
            </li>
          </ul>
        </div>
      </section>

      <section className="contact-one">
        <div className="container wow fadeInUp" data-wow-delay="300ms">
          <div className="section-title text-center">
            <h5 className="section-title__tagline">
              Contact with Us
              <svg
                className="arrow-svg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 55 13"
              >
                <g clipPath="url(#clip0_324_36194)">
                  <path d="M10.5406 6.49995L0.700562 12.1799V8.56995L4.29056 6.49995L0.700562 4.42995V0.819946L10.5406 6.49995Z" />
                  <path d="M25.1706 6.49995L15.3306 12.1799V8.56995L18.9206 6.49995L15.3306 4.42995V0.819946L25.1706 6.49995Z" />
                  <path d="M39.7906 6.49995L29.9506 12.1799V8.56995L33.5406 6.49995L29.9506 4.42995V0.819946L39.7906 6.49995Z" />
                  <path d="M54.4206 6.49995L44.5806 12.1799V8.56995L48.1706 6.49995L44.5806 4.42995V0.819946L54.4206 6.49995Z" />
                </g>
              </svg>
            </h5>
            <h2 className="section-title__title">
              Feel Free to Write us Anytime
            </h2>
          </div>

          <div className="contact-one__form-box text-center">
            {send === false ? (
              <form
                className="contact-one__form contact-form-validated"
                noValidate="novalidate"
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="contact-one__input-box">
                      <input
                        type="text"
                        id="name"
                        placeholder="Your Name"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        autoComplete="on"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="contact-one__input-box">
                      <input
                        type="email"
                        id="email"
                        placeholder="Email Address"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="on"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="contact-one__input-box">
                      <input
                        type="text"
                        id="phone"
                        placeholder="Phone"
                        name="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        autoComplete="on"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="contact-one__input-box">
                      <input
                        type="text"
                        id="subject"
                        placeholder="Subject"
                        name="subject"
                        value={subject}
                        onChange={(e) => setSub(e.target.value)}
                        autoComplete="on"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="contact-one__input-box text-message-box">
                      <textarea
                        name="message"
                        id="message"
                        placeholder="Write a Message"
                        value={msg}
                        onChange={(e) => setMsg(e.target.value)}
                        autoComplete="on"
                      />
                    </div>
                    {error && (
                      <div
                        style={{
                          backgroundColor: "#FF5733",
                          width: "100%",
                          marginBottom: 8,
                          padding: 8,
                          color: "#FFF",
                          fontSize: 18,
                          borderRadius: 8,
                        }}
                      >
                        {error}
                      </div>
                    )}
                    <div className="contact-one__btn-box">
                      <button
                        type="button"
                        onClick={submit}
                        className="eduact-btn eduact-btn-second"
                      >
                        <span className="eduact-btn__curve" />
                        Send a Message
                        <i className="icon-arrow" />
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              <div className="card p-3">
                <h2 className="section-title__title">
                  Thank you for contacting us...
                </h2>
                <p>
                  Your message is on first priority for us. We will get back to
                  you soon.
                </p>
              </div>
            )}
          </div>
        </div>
      </section>

      <section className="contact-info">
        <div className="container">
          <ul className="contact-info__wrapper">
            <li style={{ height: 230 }}>
              <div className="contact-info__icon">
                <span className="icon-Call" />
              </div>
              <p className="contact-info__title">Have any question?</p>
              <h4 className="contact-info__text">
                Free <a href="tel:230008050">(+91) 98798-14480</a>
              </h4>
            </li>
            <li className="active" style={{ height: 230 }}>
              <div className="contact-info__icon">
                <span className="icon-Email" />
              </div>
              <p className="contact-info__title">Need help?</p>
              <h4 className="contact-info__text">
                Free <a href="mailto:info@recallx.in">info@recallx.in</a>
              </h4>
            </li>
            <li style={{ height: 230 }}>
              <div className="contact-info__icon">
                <span className="icon-Location" />
              </div>
              <p className="contact-info__title">Find Us</p>
              <h4 className="contact-info__text">
                B-13, Madhav Baug Society, Vasai Road
              </h4>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};
