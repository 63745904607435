import React from "react";
import { NavLink, useLocation } from "react-router-dom";

export const PageHeader = ({ mmOpen }) => {
  const location = useLocation();
  return (
    <header className="main-header-two">
      <nav className="main-menu">
        <div className="container">
          <div className="main-menu__logo">
            <a href="/">
              <img
                src="assets/images/recallx-logo.png"
                height={70}
                alt="Eduact"
              />
            </a>
          </div>
          {/* /.main-menu__logo */}
          <div className="main-menu__nav">
            <ul className="main-menu__list">
              <li className={location.pathname === "/" ? "current" : ""}>
                <NavLink to="/">Home</NavLink>
              </li>
              <li className={location.pathname === "/about" ? "current" : ""}>
                <NavLink to="/about">About Us</NavLink>
              </li>
              <li className={location.pathname === "/course" ? "current" : ""}>
                <NavLink to="/course">Course</NavLink>
              </li>
              <li
                className={location.pathname === "/checkout" ? "current" : ""}
              >
                <NavLink to="/checkout">Buy Course</NavLink>
              </li>
              <li className={location.pathname === "/faq" ? "current" : ""}>
                <NavLink to="/faq">FAQ</NavLink>
              </li>
              <li className={location.pathname === "/blogs" ? "current" : ""}>
                <NavLink to="/blogs">Blog</NavLink>
              </li>

              <li
                className={location.pathname === "/contact-us" ? "current" : ""}
              >
                <NavLink to="/contact-us">Contact</NavLink>
              </li>
            </ul>
          </div>
          {/* /.main-menu__nav */}
          <div className="main-menu__right">
            <a
              className="main-menu__toggler mobile-nav__toggler"
              onClick={mmOpen}
            >
              <i className="fa fa-bars" />
            </a>

            {/* /.login btn */}
            <div className="main-menu__info">
              <span className="icon-Call" />
              <a href="tel:+919879814480">98798 14480</a>
              Call to Questions
            </div>
            {/* /.info */}
          </div>
          {/* /.main-menu__right */}
        </div>
        {/* /.container */}
      </nav>
      {/* /.main-menu */}
    </header>
  );
};
