import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "owl.carousel/dist/assets/owl.carousel.css";
import CountUp from "react-countup";
import { TestimonialHome } from "./TestimonialHome";
import LatestBlog from "./LatestBlog";
import { Link } from "react-router-dom";
import MainCarousel from "./MainCarousel";
import { DiscountSection } from "./DiscountSection";

export default function Home() {
  const [startCounter, setStartCounter] = useState(false);

  const handleMouseEnter = () => {
    // Start the counter only if it hasn't started yet
    if (!startCounter) {
      setStartCounter(true);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Home | RecallX</title>
      </Helmet>

      {/*Main Slider Start*/}
      <section className="main-slider">
        <MainCarousel />
        <div
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/shapes/slider-bg-1-Copy.jpg)`,
            textAlign: "center",
          }}
          className="d-lg-none d-xl-none d-md-none d-sm-block pt-3"
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/main-img.png`}
            alt="slider layer"
            style={{ width: "90%" }}
          />
        </div>
      </section>
      <section className="video-one">
        <div className="container">
          <div
            className="video-one__bg"
            style={{
              backgroundImage: "url(assets/images/backgrounds/video-bg-1.jpg)",
            }}
          >
            <div
              className="video-one__shape"
              style={{
                backgroundImage: "url(assets/images/shapes/video-shape-1.png)",
              }}
            />
            <div
              className="video-one__shape2"
              style={{
                backgroundImage: "url(assets/images/shapes/video-shape-2.png)",
              }}
            />
            <div className="row">
              <div
                className="col-lg-6 col-md-7 wow fadeInLeft"
                data-wow-delay="200ms"
              >
                <h3 className="video-one__title">
                  Watch the full introduction of the course
                </h3>
                <span style={{ fontSize: 65, color: "#f57005" }}>{">>>"}</span>
              </div>
              <div
                className="col-lg-6 col-md-5 wow fadeInRight"
                data-wow-delay="200ms"
              >
                <div className="video-one__btn">
                  <img src="assets/images/33.jpg" alt="eduact" />
                  <Link to="./videos" className="video-popup">
                    <span className="icon-play" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*Main Slider End*/}
      {/* About Start */}
      <section className="about-three">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 wow fadeInLeft" data-wow-delay="100ms">
              <div className="about-three__content">
                {/* about content start*/}
                <div className="section-title">
                  <h5 className="section-title__tagline">
                    About Us
                    <svg
                      className="arrow-svg"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 55 13"
                    >
                      <g clipPath="url(#clip0_324_36194)">
                        <path d="M10.5406 6.49995L0.700562 12.1799V8.56995L4.29056 6.49995L0.700562 4.42995V0.819946L10.5406 6.49995Z" />
                        <path d="M25.1706 6.49995L15.3306 12.1799V8.56995L18.9206 6.49995L15.3306 4.42995V0.819946L25.1706 6.49995Z" />
                        <path d="M39.7906 6.49995L29.9506 12.1799V8.56995L33.5406 6.49995L29.9506 4.42995V0.819946L39.7906 6.49995Z" />
                        <path d="M54.4206 6.49995L44.5806 12.1799V8.56995L48.1706 6.49995L44.5806 4.42995V0.819946L54.4206 6.49995Z" />
                      </g>
                    </svg>
                  </h5>
                  <h2 className="section-title__title">
                    Master Speed Reading with Recall X!
                  </h2>
                </div>
                {/* section-title */}
                <p className="about-three__content__text">
                  Imagine being able to read texts, reports, or study materials
                  for exams three times faster without facing any delays. The
                  best speed reading course accessible, Recall X, is designed
                  for all types of readers, including students, continuous
                  learners, and professionals. You'll learn scientifically
                  proven methods to increase your reading speed and retention in
                  just 26 days.
                </p>
                <p style={{ fontSize: 18, fontStyle: "italic" }}>
                  <span style={{ fontSize: 25, fontWeight: "bold" }}>"</span>
                  The faster you read, the more you learn — maximise your time
                  with Recall X!
                  <span style={{ fontSize: 25, fontWeight: "bold" }}>"</span>
                </p>
                <div className="about-three__box">
                  <div className="about-three__box__icon">
                    <span className="icon-Presentation" />
                  </div>
                  <h4 className="about-three__box__title">
                    Start Your Speed Reading Journey Today
                  </h4>
                  <p className="about-three__box__text">
                    Our 26-day program is carefully designed to make sure you
                    get the most out of every session.
                  </p>
                </div>
                {/* /.icon-box */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="about-three__author">
                      <div className="about-three__author__thumb">
                        <img src="assets/images/nilay-doshi.png" alt="eduact" />
                      </div>
                      <p style={{ fontSize: 21, fontWeight: "bold" }}>
                        Mr. Nilay Doshi
                      </p>
                      <p className="about-three__author__text">
                        Your Coach , Founder
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="about-three__info">
                      <div className="about-three__info__icon">
                        <span className="icon-Call" />
                      </div>
                      <p className="about-three__info__text">
                        Need to Know More Details?
                      </p>
                      <h4 className="about-three__info__title">
                        <a href="tel:919879814480">+(91) 98798 14480</a>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="about-three__br" />
              </div>
              {/* about content end */}
            </div>
            <div className="col-xl-6 wow fadeInRight" data-wow-delay="100ms">
              <div className="about-three__thumb">
                {/* about thumb start */}
                <div
                  className="about-three__thumb__one eduact-tilt"
                  data-tilt-options='{ "glare": false, "maxGlare": 0, "maxTilt": 2, "speed": 700, "scale": 1 }'
                >
                  <img src="assets/images/front-cover.jpg" alt="eduact" />
                </div>
                {/* /.about-thumb-one */}
                <div className="about-three__thumb__shape-one" />
                {/* /.about-shape-one */}
                <div className="about-three__thumb__shape-two" />
                {/* /.about-shape-two */}
                <div className="about-three__thumb__shape-three">
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                </div>
                {/* /.about-shape-three */}
                <div className="about-three__thumb__shape-four">
                  <img
                    src="assets/images/shapes/about-3-shape-1.png"
                    alt="eduact"
                  />
                </div>
                {/* /.about-shape-four */}
                <div className="about-three__thumb__shape-five">
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                </div>
                {/* /.about-shape-five */}
                <div className="about-three__thumb__shape-six">
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                </div>
                {/* /.about-shape-six */}
                <div className="about-three__thumb__shape-seven" />
                {/* /.about-shape-seven */}
              </div>
              {/* about thumb end */}
            </div>
          </div>
        </div>
      </section>
      <DiscountSection />
      {/* About End */}
      {/* About Start */}
      <section className="about-two">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="about-two__content">
                {/* about content start*/}
                <div className="section-title">
                  <h2 className="section-title__title">
                    What Makes Recall X Special?
                  </h2>
                </div>
                {/* section-title */}
                <p className="about-two__content__text">
                  Recall X is an educational course that delivers measurable
                  results quickly through years of research and practical
                  implementation. Our course of study has been thoughtfully
                  created to improve the way you take in, retain, and use
                  knowledge in all facets of your life—not just to help you read
                  more quickly.
                </p>
                {/* /.icon-box */}
              </div>
              <h4 className="about-three__box__title mb-4">
                Key Features of Recall X:
              </h4>

              <ul className="about-two__lists clearfix">
                <li>
                  <span className="icon-check" />
                  Triple your reading speed.
                </li>
                <li>
                  <span className="icon-check" />
                  3X your focus
                </li>
                <li>
                  <span className="icon-check" />
                  Improve memory retention.
                </li>
                <li>
                  <span className="icon-check" />
                  3X your creativity.
                </li>
                <li>
                  <span className="icon-check" />
                  Boost your cognitive and comprehension skills.
                </li>
                <li>
                  <span className="icon-check" />
                  3X your concentration.
                </li>
                <li>
                  <span className="icon-check" />
                  Skills to remember without re-reading.
                </li>
              </ul>
            </div>
            <div className="col-xl-6">
              <div className="about-one__box">
                <div className="about-one__box__wrapper">
                  <div className="about-one__box__icon">
                    <span className="icon-Presentation"></span>
                  </div>
                  <h4 className="about-one__box__title">Planned Program</h4>
                  <p className="about-one__box__text">
                    A planned 26-day program with daily 25-minute sessions meant
                    for steady improvement.
                  </p>
                </div>
              </div>
              <div className="about-one__box">
                <div className="about-one__box__wrapper">
                  <div className="about-one__box__icon">
                    <span className="icon-Presentation"></span>
                  </div>
                  <h4 className="about-one__box__title">
                    Scientifically Supported Methods
                  </h4>
                  <p className="about-one__box__text">
                    Dependable, scientifically supported methods for improving
                    memory, comprehension, and reading speed.
                  </p>
                </div>
              </div>
              <div className="about-one__box">
                <div className="about-one__box__wrapper">
                  <div className="about-one__box__icon">
                    <span className="icon-Presentation"></span>
                  </div>
                  <h4 className="about-one__box__title">Suitable For All</h4>
                  <p className="about-one__box__text">
                    Suitable for students, professionals, and anyone looking for
                    ways to enhance learning effectiveness.
                  </p>
                </div>
              </div>
              <div className="about-one__box">
                <div className="about-one__box__wrapper">
                  <div className="about-one__box__icon">
                    <span className="icon-Presentation"></span>
                  </div>
                  <h4 className="about-one__box__title">Long-term success</h4>
                  <p className="about-one__box__text">
                    For long-term success, improve reading speed, boost memory,
                    and focus.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About End */}
      {/* Service Start */}
      <section
        className="service-two"
        style={{
          backgroundImage: "url(assets/images/shapes/service-bg-2.jpg)",
          marginTop: -50,
        }}
      >
        <div className="container">
          <div
            className="section-title text-center wow fadeInUp"
            data-wow-delay="100ms"
          >
            <h2 className="section-title__title">
              Why Thousands Trust Recall X
            </h2>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div
                className="section-title wow fadeInLeft"
                data-wow-delay="100ms"
              >
                <h5 className="section-title__tagline">
                  Why Choose Recall X?
                  <svg
                    className="arrow-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 55 13"
                  >
                    <g clipPath="url(#clip0_324_36194)">
                      <path d="M10.5406 6.49995L0.700562 12.1799V8.56995L4.29056 6.49995L0.700562 4.42995V0.819946L10.5406 6.49995Z" />
                      <path d="M25.1706 6.49995L15.3306 12.1799V8.56995L18.9206 6.49995L15.3306 4.42995V0.819946L25.1706 6.49995Z" />
                      <path d="M39.7906 6.49995L29.9506 12.1799V8.56995L33.5406 6.49995L29.9506 4.42995V0.819946L39.7906 6.49995Z" />
                      <path d="M54.4206 6.49995L44.5806 12.1799V8.56995L48.1706 6.49995L44.5806 4.42995V0.819946L54.4206 6.49995Z" />
                    </g>
                  </svg>
                </h5>
                <h2 className="section-title__title">
                  Tested Methods for
                  <br /> True Results
                </h2>
              </div>
              {/* section-title */}
            </div>
            <div className="col-lg-6 wow fadeInRight" data-wow-delay="100ms">
              <p className="service-three__section-text">
                Recall X is a course which provides long-lasting improvements to
                your reading and learning skills by combining the most recent
                findings in cognitive science with practical learning
                techniques. We help you improve your reading skills in a few
                weeks by offering you tailored instruction and ongoing
                assistance.
              </p>
            </div>
          </div>
          {/* section-title */}
          <div className="row">
            <div
              className="col-xl-3 col-md-6 wow fadeInUp"
              data-wow-delay="200ms"
            >
              <div className="service-two__item text-center">
                <div className="service-two__wrapper">
                  <svg
                    viewBox="0 0 303 117"
                    fill="#F6F6F6"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx={151} cy={-129} r={246} />
                  </svg>
                  <div className="service-two__icon">
                    <span className="icon-education" />
                  </div>
                  {/* /.service-icon */}
                  <h3 className="service-two__title">
                    <a href="#">3x Faster Reading</a>
                  </h3>
                  {/* /.service-title */}
                  <p className="service-two__text">
                    Increase reading speed three times while maintaining
                    comprehension.
                    <br />
                    <br />
                  </p>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
            <div
              className="col-xl-3 col-md-6 wow fadeInUp"
              data-wow-delay="300ms"
            >
              <div className="service-two__item text-center">
                <div className="service-two__wrapper">
                  <svg
                    viewBox="0 0 303 117"
                    fill="#F6F6F6"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx={151} cy={-129} r={246} />
                  </svg>
                  <div className="service-two__icon">
                    <span className="icon-business" />
                  </div>
                  {/* /.service-icon */}
                  <h3 className="service-two__title">
                    <a href="#">Comprehension Boost:</a>
                  </h3>
                  {/* /.service-title */}
                  <p className="service-two__text">
                    Use cutting-edge memory tricks to retain crucial
                    information.
                    <br />
                    <br />
                  </p>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
            <div
              className="col-xl-3 col-md-6 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div className="service-two__item text-center">
                <div className="service-two__wrapper">
                  <svg
                    viewBox="0 0 303 117"
                    fill="#F6F6F6"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx={151} cy={-129} r={246} />
                  </svg>
                  <div className="service-two__icon">
                    <span className="icon-neural" />
                  </div>
                  {/* /.service-icon */}
                  <h3 className="service-two__title">
                    <a href="#">Scientifically Verified</a>
                  </h3>
                  {/* /.service-title */}
                  <p className="service-two__text">
                    The learning and retention techniques we teach in our course
                    are thoroughly tried and tested.
                  </p>
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
            <div
              className="col-xl-3 col-md-6 wow fadeInUp"
              data-wow-delay="500ms"
            >
              <div className="service-two__item text-center">
                <div className="service-two__wrapper">
                  <svg
                    viewBox="0 0 303 117"
                    fill="#F6F6F6"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx={151} cy={-129} r={246} />
                  </svg>
                  <div className="service-two__icon">
                    <span className="icon-webinar" />
                  </div>
                  {/* /.service-icon */}
                  <h3 className="service-two__title">
                    <a href="#">Tailored for All</a>
                  </h3>
                  {/* /.service-title */}
                  <p className="service-two__text">
                    Excellent for researchers, professionals, students, and
                    people who love to learn.
                    <br />
                    <br />
                  </p>
                  {/* /.service-content */}
                </div>
              </div>
              {/* /.service-card-one */}
            </div>
          </div>
        </div>
      </section>
      {/* Service End */}

      {/* Counter Start */}
      <section
        className="fact-two"
        onMouseEnter={handleMouseEnter}
        style={{
          backgroundImage: "url(assets/images/shapes/fact-bg-2.png)",
        }}
      >
        <div className="container">
          <div
            className="fact-two__inner"
            style={{
              backgroundColor: "#0F118C",
              //backgroundImage: "url(assets/images/shapes/fact-bg-inner.png)",
            }}
          >
            <div className="row">
              <div
                className="col-lg-3 col-md-6 wow fadeInUp"
                data-wow-delay="100ms"
              >
                <div className="fact-two__item text-center">
                  <div className="fact-two__icon">
                    <span className="icon-Headphone-Women" />
                  </div>
                  <div className="fact-two__count">
                    <span className="count-box">
                      {startCounter ? (
                        <CountUp start={0} end={25000} duration={3} />
                      ) : (
                        0
                      )}
                    </span>
                    +
                  </div>
                  {/* /.fact-two__count */}
                  <h3 className="fact-two__title">Student Enrolled</h3>
                  {/* /.fact-two__title */}
                </div>
                {/* /.fact-item */}
              </div>
              <div
                className="col-lg-3 col-md-6 wow fadeInUp"
                data-wow-delay="200ms"
              >
                <div className="fact-two__item fact-two__item--smnone text-center">
                  <div className="fact-two__icon">
                    <span className="icon-Online-learning" />
                  </div>
                  <div className="fact-two__count">
                    <span className="count-box">
                      {startCounter ? (
                        <CountUp start={0} end={12000} duration={3} />
                      ) : (
                        0
                      )}
                    </span>
                    +
                  </div>
                  {/* /.fact-two__count */}
                  <h3 className="fact-two__title">Class completed</h3>
                  {/* /.fact-two__title */}
                </div>
                {/* /.fact-item */}
              </div>
              <div
                className="col-lg-3 col-md-6 wow fadeInUp"
                data-wow-delay="300ms"
              >
                <div className="fact-two__item text-center">
                  <div className="fact-two__icon">
                    <span className="icon-ratings" />
                  </div>
                  <div className="fact-two__count">
                    <span className="count-box">
                      {startCounter ? (
                        <CountUp start={0} end={100} duration={3} />
                      ) : (
                        0
                      )}
                    </span>
                    %
                  </div>
                  {/* /.fact-two__count */}
                  <h3 className="fact-two__title">satisfaction rate</h3>
                  {/* /.fact-two__title */}
                </div>
                {/* /.fact-item */}
              </div>
              <div
                className="col-lg-3 col-md-6 wow fadeInUp"
                data-wow-delay="400ms"
              >
                <div className="fact-two__item fact-two__item--noborder text-center">
                  <div className="fact-two__icon">
                    <span className="icon-instructor" />
                  </div>
                  <div className="fact-two__count">
                    <span className="count-box">
                      {startCounter ? (
                        <CountUp start={0} end={125} duration={3} />
                      ) : (
                        0
                      )}
                    </span>
                    +
                  </div>
                  {/* /.fact-two__count */}
                  <h3 className="fact-two__title">Top instructors</h3>
                  {/* /.fact-two__title */}
                </div>
                {/* /.fact-item */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Counter End */}
      {/* Course Start */}

      {/* Course End */}
      {/* Category Start */}
      <section className="service-one">
        <div className="service-one__bg" />
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <div
                className="section-title wow fadeInUp"
                data-wow-delay="100ms"
              >
                <h2 className="section-title__title">
                  What Changes Will the Course Bring to You After Completion?
                </h2>
                <p style={{ fontSize: 18, marginTop: 25 }}>
                  The human brain and eye can read in a coordinated way with a
                  high comprehension rate of over 500 words per minute. To
                  obtain such a rapid reading speed, you will undoubtedly want
                  to know or learn some unique strategies. A person with a high
                  reading speed has all of the advantages of receiving better
                  grades as a student or advancing fast in his or her job as a
                  businessperson.
                </p>
              </div>
              <h5 className="section-title__tagline">
                At the end of our 26-day course,
                <br /> you can expect:
                <svg
                  className="arrow-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 55 13"
                >
                  <g clipPath="url(#clip0_324_36194)">
                    <path d="M10.5406 6.49995L0.700562 12.1799V8.56995L4.29056 6.49995L0.700562 4.42995V0.819946L10.5406 6.49995Z" />
                    <path d="M25.1706 6.49995L15.3306 12.1799V8.56995L18.9206 6.49995L15.3306 4.42995V0.819946L25.1706 6.49995Z" />
                    <path d="M39.7906 6.49995L29.9506 12.1799V8.56995L33.5406 6.49995L29.9506 4.42995V0.819946L39.7906 6.49995Z" />
                    <path d="M54.4206 6.49995L44.5806 12.1799V8.56995L48.1706 6.49995L44.5806 4.42995V0.819946L54.4206 6.49995Z" />
                  </g>
                </svg>
              </h5>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="row">
                <div
                  className="col-lg-6 col-md-6 wow fadeInUp"
                  data-wow-delay="100ms"
                >
                  <div className="service-one__item about-one__box">
                    <div className="service-one__wrapper">
                      {/* /.service-icon */}
                      <h3 className="service-one__title">
                        <a href="#">Improved Reading Speed</a>
                      </h3>
                      {/* /.service-title */}
                      <p className="service-one__text">
                        Increase your reading speed while preserving important
                        details.
                        <br />
                        <br />
                        <br />
                      </p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 118 129"
                        fill="none"
                      >
                        <path
                          d="M0.582052 143.759C135.395 113.682 145.584 0.974413 145.584 0.974413L173.881 89.6286C173.881 89.6286 0.582054 322.604 0.582052 143.759Z"
                          fill="#F1F2FD"
                        />
                      </svg>
                    </div>
                  </div>
                  {/* /.service-card-one */}
                </div>
                <div
                  className="col-lg-6 col-md-6 wow fadeInUp"
                  data-wow-delay="200ms"
                >
                  <div className="service-one__item about-one__box">
                    <div className="service-one__wrapper">
                      {/* /.service-icon */}
                      <h3 className="service-one__title">
                        <a href="#">Masterful Note-Taking & Note making</a>
                      </h3>
                      {/* /.service-title */}
                      <p className="service-one__text">
                        Learn excellent note-taking skills to manage and
                        comprehend complex subjects with ease.
                      </p>
                      {/* /.service-content */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 118 129"
                        fill="none"
                      >
                        <path
                          d="M0.582052 143.759C135.395 113.682 145.584 0.974413 145.584 0.974413L173.881 89.6286C173.881 89.6286 0.582054 322.604 0.582052 143.759Z"
                          fill="#F1F2FD"
                        />
                      </svg>
                    </div>
                  </div>
                  {/* /.service-card-one */}
                </div>
                <div
                  className="col-lg-6 col-md-6 wow fadeInUp"
                  data-wow-delay="300ms"
                >
                  <div className="service-one__item about-one__box">
                    <div className="service-one__wrapper">
                      {/* /.service-icon */}
                      <h3 className="service-one__title">
                        <a href="#">Improved Cognitive Skills:</a>
                      </h3>
                      {/* /.service-title */}
                      <p className="service-one__text">
                        Utilize advanced learning strategies to improve your
                        comprehension and memory.
                        <br />
                        <br />
                      </p>
                      {/* /.service-content */}

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 118 129"
                        fill="none"
                      >
                        <path
                          d="M0.582052 143.759C135.395 113.682 145.584 0.974413 145.584 0.974413L173.881 89.6286C173.881 89.6286 0.582054 322.604 0.582052 143.759Z"
                          fill="#F1F2FD"
                        />
                      </svg>
                    </div>
                  </div>
                  {/* /.service-card-one */}
                </div>
                <div
                  className="col-lg-6 col-md-6 wow fadeInUp"
                  data-wow-delay="400ms"
                >
                  <div className="service-one__item about-one__box">
                    <div className="service-one__wrapper">
                      {/* /.service-icon */}
                      <h3 className="service-one__title">
                        <a href="#">Increased Productivity:</a>
                      </h3>
                      {/* /.service-title */}
                      <p className="service-one__text">
                        Use your newly acquired reading abilities to excel in
                        both personal and professional responsibilities, making
                        you more efficient and smart.
                      </p>
                      {/* /.service-content */}

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 118 129"
                        fill="none"
                      >
                        <path
                          d="M0.582052 143.759C135.395 113.682 145.584 0.974413 145.584 0.974413L173.881 89.6286C173.881 89.6286 0.582054 322.604 0.582052 143.759Z"
                          fill="#F1F2FD"
                        />
                      </svg>
                    </div>
                  </div>
                  {/* /.service-card-one */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-one">
        <div className="service-one__bg" />
        <div className="container">
          <span style={{ fontSize: 40, fontWeight: "bold", color: "#000" }}>
            When was the last time you learned how to read ? <br />
          </span>
          <p
            style={{
              color: "#F57005",
              fontSize: 30,
              fontWeight: "bold",
              padding: 25,
            }}
          >
            Most people start reading around the age of six
          </p>
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <div className="row">
                <div
                  className="col-lg-12 col-md-12 wow fadeInUp"
                  data-wow-delay="100ms"
                >
                  <div className="service-one__item about-one__box">
                    <div className="service-one__wrapper">
                      {/* /.service-title */}
                      <p className="service-one__text">
                        You were taught to be a slow reader and you are reading
                        it now also at the same slow pace. Too much study
                        material and growing age cause stress and anxiety and a
                        wandering mind - A monkey mind.
                      </p>
                      <p>
                        You are growing by age every standard by standard with
                        loads of content to read but you are reading at the same
                        pace as when you were 6 years old, no one had taught you
                        to read effectively.
                      </p>
                      <p>
                        And that’s the last time you learned to read. And it’s
                        time to change that old way of reading with some fresh
                        and new techniques introduced by Recall X.
                      </p>
                      <p style={{ fontSize: 21, fontStyle: "italic" }}>
                        The best time to plant a tree was 20 years ago, the
                        second best time is <b>NOW</b>
                      </p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 118 129"
                        fill="none"
                      >
                        <path
                          d="M0.582052 143.759C135.395 113.682 145.584 0.974413 145.584 0.974413L173.881 89.6286C173.881 89.6286 0.582054 322.604 0.582052 143.759Z"
                          fill="#F1F2FD"
                        />
                      </svg>
                    </div>
                  </div>
                  {/* /.service-card-one */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div
                className="section-title wow fadeInUp"
                data-wow-delay="100ms"
              >
                <h2
                  className="section-title__title"
                  style={{ fontSize: 50, marginTop: 50 }}
                >
                  Now or Later ?
                </h2>
                <h2>The Answer is….</h2>
              </div>
              <h2
                className="section-title__tagline"
                style={{ fontSize: 100, marginTop: 100 }}
              >
                Now…!
              </h2>
              <h2 className="section-title__tagline mt-5">
                But Why Now...?
                <svg
                  className="arrow-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 55 13"
                >
                  <g clipPath="url(#clip0_324_36194)">
                    <path d="M10.5406 6.49995L0.700562 12.1799V8.56995L4.29056 6.49995L0.700562 4.42995V0.819946L10.5406 6.49995Z" />
                    <path d="M25.1706 6.49995L15.3306 12.1799V8.56995L18.9206 6.49995L15.3306 4.42995V0.819946L25.1706 6.49995Z" />
                    <path d="M39.7906 6.49995L29.9506 12.1799V8.56995L33.5406 6.49995L29.9506 4.42995V0.819946L39.7906 6.49995Z" />
                    <path d="M54.4206 6.49995L44.5806 12.1799V8.56995L48.1706 6.49995L44.5806 4.42995V0.819946L54.4206 6.49995Z" />
                  </g>
                </svg>
                <Link
                  to="./contact-us"
                  style={{ marginLeft: 18, color: "#F57005" }}
                >
                  Inquiry Now
                </Link>
              </h2>
            </div>
          </div>
        </div>
      </section>

      <section className="video-one" style={{ marginBottom: 18 }}>
        <div className="container">
          <div
            className="video-one__bg"
            style={{
              backgroundImage: "url(assets/images/backgrounds/video-bg-1.jpg)",
            }}
          >
            <div
              className="video-one__shape"
              style={{
                backgroundImage: "url(assets/images/shapes/video-shape-1.png)",
              }}
            />
            <div
              className="video-one__shape2"
              style={{
                backgroundImage: "url(assets/images/shapes/video-shape-2.png)",
              }}
            />
            <div className="row">
              <div
                className="col-lg-6 col-md-7 wow fadeInLeft"
                data-wow-delay="200ms"
              >
                <h3 className="video-one__title">
                  So <span style={{ color: "#f57005" }}>NOW</span> is the best
                  time to get started!
                </h3>
                <Link to="/course" className="eduact-btn eduact-btn-second">
                  <span className="eduact-btn__curve" />
                  Discover More
                  <i className="icon-arrow" />
                </Link>
              </div>
              <div
                className="col-lg-6 col-md-5 wow fadeInRight"
                data-wow-delay="200ms"
              >
                <p style={{ fontSize: 21, color: "#FFF", fontWeight: "bold" }}>
                  Are you aware of this ?
                </p>
                <p
                  style={{
                    fontSize: 18,
                    color: "#FFF",

                    fontStyle: "italic",
                  }}
                >
                  A mass of about 8% protein, 10% fat, and 78% water makes up
                  your brain. It has a walnut-like appearance and weighs about
                  1.5 kg. 20% of your calories are consumed by it, however, it
                  only makes up about 2% of your entire body weight. The more
                  you think, the more calories you burn.
                </p>
                <p
                  style={{
                    fontSize: 21,
                    color: "#FFF",
                    fontWeight: "normal",
                  }}
                >
                  (From Tony Buzan, "Use Your Head")
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Category End */}
      {/* Testimonial Start */}
      {/* <TestimonialHome /> */}
      {/* Testimonial End */}

      {/* Blog Start */}
      <LatestBlog />
      {/* Blog End */}

      {/* Call To Action Start */}
      <section
        className="cta-three"
        data-jarallax
        data-speed="0.3"
        data-imgposition="50% -100%"
      >
        <div
          className="cta-three__bg jarallax-img"
          style={{
            backgroundImage: "url(assets/images/backgrounds/cta-bg-3.jpg)",
          }}
        />
        <div className="container">
          <div className="row">
            <div className="col-md-6 wow fadeInLeft" data-wow-delay="200ms">
              <div className="cta-three__thumb">
                <img src="assets/images/shapes/cta-3-1.png" alt="eduact" />
              </div>
              <h3 className="cta-three__title">
                <span>Skills Certificate</span>
                <br /> From the <span>RecallX</span>
              </h3>
            </div>
            <div className="col-md-6 wow fadeInRight" data-wow-delay="300ms">
              <div className="cta-three__btn">
                {/* <Link to="/contact-us" className="eduact-btn">
                    <span className="eduact-btn__curve" />
                    Get In Touch
                  </Link> */}
                <Link to="/checkout" className="eduact-btn">
                  <span className="eduact-btn__curve" />
                  Buy Course
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Call To Action End */}

      {/* /.page-wrapper */}
    </div>
  );
}
