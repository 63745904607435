import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export const TestimonialHome = () => {
  const options = {
    items: 3, // Ensure this is a number
    margin: 30, // Ensure this is defined
    loop: true,
    autoplay: true,
    center: true,
    nav: true,
    dots: false,
    navText: [
      '<span class="icon-arrow-left"></span>',
      '<span class="icon-arrow"></span>',
    ],
    responsive: {
      0: { items: 1 },
      700: { items: 1 },
      992: { items: 3 },
      1200: { items: 3 },
    },
  };

  const data = [
    {
      id: 1,
      img: "https://api.recallx.in/data/jayanthi manglagiri.jpeg",
      name: "Jayanthi Mangalagiri",
      text: "Nilay Doshi’s RecallX course has been instrumental in helping me prepare for my exams. His techniques are simple yet extremely effective for retaining even the most complicated topics.",
      location: "Visakhapatnam",
    },
    {
      id: 2,
      img: "https://api.recallx.in/data/shivangi mudgal.jpeg",
      name: "Shivangi Mudgal",
      text: "Nilay Doshi has completely revolutionized how I approach studying. His RecallX program is perfect for tackling difficult subjects and remembering them effortlessly.",
      location: "Indore",
    },
    {
      id: 3,
      img: "https://api.recallx.in/data/megha parikh.jpeg",
      name: "Megha Parikh",
      text: "My son took the RecallX Speed Reading Course, and the results have been incredible! He now reads much faster and retains information better. The daily sessions were engaging and made learning enjoyable. Highly recommend it!",
      location: "Vadodara",
    },
    {
      id: 4,
      img: "https://api.recallx.in/data/vidhi hemnani.jpeg",
      name: "Vidhi Hemnani",
      text: "I can’t believe the results after completing RecallX. My reading speed improved dramatically, and the note-making techniques have been incredibly effective. Nilay Doshi’s course is packed with practical tips, and I highly recommend it to everyone!",
      location: "Lucknow",
    },
    {
      id: 5,
      img: "https://api.recallx.in/data/parita pathak.jpeg",
      name: "Parita Pathak",
      text: "The RecallX masterclass is a game-changer for anyone who struggles with retaining information. The course is thorough and designed for all ages, making reading complex topics so much easier.",
      location: "Ahmedabad",
    },
    {
      id: 6,
      img: "https://api.recallx.in/data/mamta joshi.jpeg",
      name: "Mamta Joshi",
      text: "RecallX is a beautifully designed program that helped me increase my reading speed and comprehension significantly. The techniques are perfect for anyone wanting to handle lengthy and difficult topics.",
      location: "Ahmedabad",
    },
    {
      id: 7,
      img: "https://api.recallx.in/data/kosha jariwala.jpeg",
      name: "Kosha Jariwala",
      text: "I cannot thank Nilay Doshi enough for this amazing course! His RecallX program has helped me improve my focus, speed, and memory retention like never before.",
      location: "Vadodara",
    },
    {
      id: 8,
      img: "https://api.recallx.in/data/grishma shah.jpeg",
      name: "Grishma Shah",
      text: "RecallX is perfect for all ages. It’s beautifully structured, easy to follow, and highly effective. I now manage complex topics with ease and can retain much more information.",
      location: "Kochi",
    },
    {
      id: 9,
      img: "https://api.recallx.in/data/hiral doshi.jpeg",
      name: "Hiral Doshi",
      text: "The RecallX Speed Reading Course exceeded my expectations! My son now reads three times faster and remembers more of what he reads. The instructors were fantastic and very supportive.",
      location: "Pune",
    },
    {
      id: 10,
      img: "https://api.recallx.in/data/avinash bhalerao.jpeg",
      name: "Avinash Bhalerao",
      text: "After completing RecallX, I’ve seen a huge difference in my reading speed and ability to recall information effortlessly. Nilay Doshi’s techniques for note-taking are unique and highly effective. This course is a must for anyone looking to improve their learning skills.",
      location: "Surat",
    },
    {
      id: 11,
      img: "https://api.recallx.in/data/krishna parikh.jpeg",
      name: "Krishna Parikh",
      text: "Nilay Doshi’s RecallX course has completely changed the way I approach learning. His techniques are so easy to implement and have helped me remember even the most complex topics with ease.",
      location: "Jaipur",
    },
    {
      id: 12,
      img: "https://api.recallx.in/data/harsha ramwani.jpeg",
      name: "Harsha Ramwnai",
      text: "Nilay Doshi’s RecallX program has transformed my study habits. His methods are simple yet powerful, and now I can tackle difficult topics without stress.",
      location: "Varanasi",
    },
    {
      id: 13,
      img: "https://api.recallx.in/data/mamta purabia.jpeg",
      name: "Mamta Purabia",
      text: "RecallX is a fantastic course! It’s helped me increase my reading speed and retain information without the need for notes. This course is a must for anyone serious about learning.",
      location: "Kolkata",
    },
    {
      id: 14,
      img: "https://api.recallx.in/data/sejal gandhi.jpeg",
      name: "Sejal Gandhi",
      text: "RecallX is a life-saver! I’ve seen a drastic improvement in my reading speed, and I can now retain information without cramming. This course is a must for anyone looking to study smarter.",
      location: "Mumbai",
    },
    {
      id: 15,
      img: "https://api.recallx.in/data/priya gupta.jpeg",
      name: "Priya Gupta",
      text: "RecallX is hands down the best course I’ve taken for improving speed reading and memory skills. Nilay Doshi’s insights and teaching techniques are invaluable. I’ve seen a huge transformation, and I’m so glad I took this course!",
      location: "Chennai",
    },
    {
      id: 16,
      img: "https://api.recallx.in/data/jinal kachhiya.jpeg",
      name: "Jinal Kachhiya",
      text: "The RecallX course was exactly what I needed to enhance my reading and memory skills. Nilay Doshi has done a fantastic job with this pre-recorded program. It’s easy to follow, and I’ve experienced a big transformation in how I approach learning.",
      location: "New Delhi",
    },
    {
      id: 17,
      img: "https://api.recallx.in/data/drashti shah.jpeg",
      name: "Drashti Shah",
      text: "Nilay Doshi’s teaching style in RecallX is phenomenal. He breaks down complex ideas and makes them easy to understand, helping me remember lengthy topics with minimal effort.",
      location: "Ahmedabad",
    },
    {
      id: 18,
      img: "https://api.recallx.in/data/akansha sarolkar.jpeg",
      name: "Akasha Sarolkar",
      text: "RecallX helped me immensely with improving my reading speed and memory. The course, led by Nilay Doshi, is structured perfectly to help retain information effortlessly. My productivity has skyrocketed, and I couldn’t be more thankful! Highly recommend it.",
      location: "Indore",
    },
    {
      id: 19,
      img: "https://api.recallx.in/data/kinjal roa.jpeg",
      name: "Kinjal Rao",
      text: "RecallX! Enrolling in the RecallX speed reading program has been life-changing! I saw a huge transformation in my reading speed and the ability to recall information easily. The note-taking vs. note-making techniques were game-changers. Nilay Doshi is an incredible coach!",
      location: "Hyderabad",
    },
    {
      id: 20,
      img: "https://api.recallx.in/data/khushbu mehta.jpeg",
      name: "Khushbu Mehta",
      text: "Excellent Coaching by Nilay Doshi RecallX taught me how to read faster and retain information with ease. Nilay Doshi’s coaching style and the course structure are excellent. The lessons on note-taking vs. note-making have been incredibly helpful. A must-try for anyone looking to improve their skills.",
      location: "Gandhinagar",
    },
    {
      id: 21,
      img: "https://api.recallx.in/data/nikita kapadiya.jpeg",
      name: "Nikita Kapadiya",
      text: "Very impressed with RecallX! My child learned to read faster and retain information much better. The structured format made it easy to follow, and the daily drills were beneficial.",
      location: "Surat",
    },
    {
      id: 22,
      img: "https://api.recallx.in/data/gayatri patel.jpeg",
      name: "Gayatri Patel",
      text: "I highly recommend RecallX for any parent looking to boost their child's reading skills. My daughter has become more efficient in her studies, thanks to the practical techniques she learned.",
      location: "Anand",
    },
    {
      id: 23,
      img: "https://api.recallx.in/data/konkatti padmavati.jpeg",
      name: "Kontakti Padmavati",
      text: "I enrolled my daughter in the RecallX course, and I can’t believe the transformation! She has improved her reading speed and comprehension significantly. The structured approach really works!",
      location: "Chennai",
    },
    {
      id: 24,
      img: "https://api.recallx.in/data/Shyam Babariya.jpeg",
      name: "Shyam Babariya",
      text: "Recall X makes it easy, fast, and efficient to say to yourself, 'I can learn anything that I need to learn.’ Recall X, one of the foremost authorities in India on this subject.",
      location: "Amreli",
    },
    {
      id: 25,
      img: "https://api.recallx.in/data/Aryan Solanki.jpeg",
      name: "Aryan Solanki",
      text: "Before the training, I was able to read at 150 WPM, and after completion of the course, I was able to read at 350 WPM. This is an awesome product, and I highly recommend it. I don’t get paid anything for saying this. Even if you don’t think you need to know how to read faster, DO IT.",
      location: "Rajkot",
    },
    {
      id: 26,
      img: "https://api.recallx.in/data/Krish Upadhyay.jpeg",
      name: "Krish Upadhyay",
      text: "Recallx wan an great experience for me as I have lots of study material to read which used to take lots of my time nd was not able to remember stuff but after recallx program I'm able to schedule my reading time and also able to remember it in half of my time.becoz of their techniques of note making I'm able to make whole chapter in single sheet nd revise it well... Thank you so much sir for your efforts",
      location: "Chennai",
    },
    {
      id: 27,
      img: "https://api.recallx.in/data/Manav Jivani.jpeg",
      name: "Manav Jivani",
      text: "Superb concept. Innovative, very helpful, less time consuming , smart and too much helpful to the coming generation.",
      location: "Botad",
    },

    {
      id: 28,
      img: "https://api.recallx.in/data/Meet Patel.jpeg",
      name: "Meet Patel",
      text: "So many new things we come to know about...including reading speed it improves the brain exercise and we learn how to rember better...wonderful program.",
      location: "Ahmedabad",
    },
  ];

  return (
    <section className="testimonial-one">
      <div className="container">
        <div className="section-title text-center">
          <h5 className="section-title__tagline">
            Testimonial
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 133 13"
              fill="none"
            >
              <path
                d="M9.76794 0.395L0.391789 9.72833C-0.130596 10.2483 -0.130596 11.095 0.391789 11.615C0.914174 12.135 1.76472 12.135 2.28711 11.615L11.6633 2.28167C12.1856 1.76167 12.1856 0.915 11.6633 0.395C11.1342 -0.131667 10.2903 -0.131667 9.76794 0.395Z"
                fill="#F1F2FD"
              />
              <path
                d="M23.1625 0.395L13.7863 9.72833C13.2639 10.2483 13.2639 11.095 13.7863 11.615C14.3087 12.135 15.1593 12.135 15.6816 11.615L25.0578 2.28167C25.5802 1.76167 25.5802 0.915 25.0578 0.395C24.5287 -0.131667 23.6849 -0.131667 23.1625 0.395Z"
                fill="#F1F2FD"
              />
              <path
                d="M36.5569 0.395L27.1807 9.72833C26.6583 10.2483 26.6583 11.095 27.1807 11.615C27.7031 12.135 28.5537 12.135 29.076 11.615L38.4522 2.28167C38.9746 1.76167 38.9746 0.915 38.4522 0.395C37.9231 -0.131667 37.0793 -0.131667 36.5569 0.395Z"
                fill="#F1F2FD"
              />
              <path
                d="M49.9514 0.395L40.5753 9.72833C40.0529 10.2483 40.0529 11.095 40.5753 11.615C41.0976 12.135 41.9482 12.135 42.4706 11.615L51.8467 2.28167C52.3691 1.76167 52.3691 0.915 51.8467 0.395C51.3176 -0.131667 50.4738 -0.131667 49.9514 0.395Z"
                fill="#F1F2FD"
              />
              <path
                d="M63.3459 0.395L53.9698 9.72833C53.4474 10.2483 53.4474 11.095 53.9698 11.615C54.4922 12.135 55.3427 12.135 55.8651 11.615L65.2413 2.28167C65.7636 1.76167 65.7636 0.915 65.2413 0.395C64.7122 -0.131667 63.8683 -0.131667 63.3459 0.395Z"
                fill="#F1F2FD"
              />
              <path
                d="M76.7405 0.395L67.3643 9.72833C66.8419 10.2483 66.8419 11.095 67.3643 11.615C67.8867 12.135 68.7373 12.135 69.2596 11.615L78.6358 2.28167C79.1582 1.76167 79.1582 0.915 78.6358 0.395C78.1067 -0.131667 77.2629 -0.131667 76.7405 0.395Z"
                fill="#F1F2FD"
              />
              <path
                d="M90.1349 0.395L80.7587 9.72833C80.2363 10.2483 80.2363 11.095 80.7587 11.615C81.2811 12.135 82.1317 12.135 82.6541 11.615L92.0302 2.28167C92.5526 1.76167 92.5526 0.915 92.0302 0.395C91.5011 -0.131667 90.6573 -0.131667 90.1349 0.395Z"
                fill="#F1F2FD"
              />
              <path
                d="M103.529 0.395L94.1533 9.72833C93.6309 10.2483 93.6309 11.095 94.1533 11.615C94.6756 12.135 95.5262 12.135 96.0486 11.615L105.425 2.28167C105.947 1.76167 105.947 0.915 105.425 0.395C104.896 -0.131667 104.052 -0.131667 103.529 0.395Z"
                fill="#F1F2FD"
              />
              <path
                d="M116.924 0.395L107.548 9.72833C107.025 10.2483 107.025 11.095 107.548 11.615C108.07 12.135 108.921 12.135 109.443 11.615L118.819 2.28167C119.342 1.76167 119.342 0.915 118.819 0.395C118.29 -0.131667 117.446 -0.131667 116.924 0.395Z"
                fill="#F1F2FD"
              />
              <path
                d="M130.318 0.395L120.942 9.72833C120.42 10.2483 120.42 11.095 120.942 11.615C121.465 12.135 122.315 12.135 122.838 11.615L132.214 2.28167C132.736 1.76167 132.736 0.915 132.214 0.395C131.685 -0.131667 130.841 -0.131667 130.318 0.395Z"
                fill="#F1F2FD"
              />
            </svg>
          </h5>
          <h2 className="section-title__title">What Our Student Feedback</h2>
        </div>
        {/* section-title */}
        <div className="testimonial-one__area">
          <div className="row">
            <div className="col-lg-8 col-mb-8 col-sm-8">
              <OwlCarousel
                className="eduact-owl__carousel owl-with-shadow owl-theme"
                items={1}
              >
                {data.map((data) => {
                  return (
                    <div
                      className="item"
                      style={{ width: "100%", marginTop: 100 }}
                    >
                      <div className="testimonial-one__item">
                        <div className="testimonial-one__author">
                          <img src={data.img} alt={data.name} />
                        </div>
                        {/* testimonial-author */}
                        <div className="testimonial-one__content">
                          <div className="testimonial-one__quote">
                            {data.text}
                          </div>
                          {/* testimonial-quote */}
                          <div className="testimonial-one__meta">
                            <h5 className="testimonial-one__title">
                              {data.name}
                            </h5>
                            <span className="testimonial-one__designation">
                              {data.location}
                            </span>
                          </div>
                          {/* testimonial-meta */}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>
          </div>

          <div
            className="testimonial-one__thumb wow fadeInUp"
            data-wow-delay="200ms"
          >
            <img
              src={"assets/images/33.jpg"}
              style={{ width: 450 }}
              alt="eduact"
            />
            <svg
              viewBox="0 0 612 563"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M595.211 330.916C584.04 315.536 581.185 295.533 588.393 277.948C591.708 269.857 593.359 260.893 592.899 251.505C591.377 220.18 566.027 194.704 534.708 192.994C534.495 192.982 534.283 192.971 534.071 192.959C520.328 192.346 507.517 185.776 499.106 174.89C498.458 174.053 497.809 173.215 497.148 172.39C485.069 157.234 481.695 137.196 487.463 118.703C489.268 112.9 490.07 106.661 489.634 100.174C487.923 74.7337 467.02 54.3769 441.54 53.2801C426.665 52.6432 413.147 58.4459 403.521 68.129C386.44 85.3367 363.449 95.4207 339.207 95.1495C338.429 95.1377 337.638 95.1377 336.86 95.1377C332.79 95.1377 328.803 95.2674 321.631 94.4418C300.244 91.9768 280.473 82.2348 264.76 67.5039C244.483 48.4916 216.997 37.063 186.846 37.7471C129.15 39.0563 80.9264 88.6391 79.2159 146.325C77.9301 189.774 109.827 226.101 118.757 239.239C145.441 278.431 123.193 329.536 93.867 364.199C75.677 385.7 64.8598 413.652 65.3434 444.14C66.3697 509.161 119.548 562.153 184.581 562.99C207.784 563.285 229.501 557.046 248.021 545.995C285.428 523.668 327.033 509.161 370.031 502.167C393.635 498.323 415.883 490.456 436.043 479.275C458.739 466.69 485.128 461.914 510.702 466.337C516.117 467.268 521.708 467.705 527.429 467.575C573.659 466.537 611.16 428.513 611.584 382.279C611.773 363.067 605.663 345.316 595.211 330.916Z"
                fill="#4F5DE4"
              />
              <path
                d="M103.524 314.265C122.402 295.39 122.402 264.788 103.524 245.913C84.6458 227.038 54.038 227.038 35.1597 245.913C16.2814 264.788 16.2814 295.39 35.1597 314.265C54.038 333.14 84.6458 333.14 103.524 314.265Z"
                fill="#4F5DE4"
              />
              <path
                d="M519.408 173.899C529.715 173.899 538.07 165.546 538.07 155.241C538.07 144.936 529.715 136.582 519.408 136.582C509.101 136.582 500.746 144.936 500.746 155.241C500.746 165.546 509.101 173.899 519.408 173.899Z"
                fill="#4F5DE4"
              />
              <path
                d="M404.941 42.6715C408.221 39.3921 408.221 34.0752 404.941 30.7958C401.661 27.5164 396.343 27.5164 393.063 30.7958C389.783 34.0752 389.783 39.3921 393.063 42.6715C396.343 45.9509 401.661 45.9509 404.941 42.6715Z"
                fill="#4F5DE4"
              />
              <path
                d="M450.459 39.6567C457.747 32.3702 457.747 20.5565 450.459 13.27C443.171 5.98349 431.355 5.9835 424.067 13.27C416.78 20.5565 416.78 32.3703 424.067 39.6568C431.355 46.9433 443.171 46.9432 450.459 39.6567Z"
                fill="#4F5DE4"
              />
              <path
                d="M469.475 508.914C476.947 508.914 483.005 502.857 483.005 495.386C483.005 487.914 476.947 481.858 469.475 481.858C462.002 481.858 455.944 487.914 455.944 495.386C455.944 502.857 462.002 508.914 469.475 508.914Z"
                fill="#4F5DE4"
              />
              <path
                d="M341.696 525.638C343.481 525.638 344.929 524.191 344.929 522.406C344.929 520.621 343.481 519.175 341.696 519.175C339.911 519.175 338.464 520.621 338.464 522.406C338.464 524.191 339.911 525.638 341.696 525.638Z"
                fill="url(#paint0_linear_187_13357)"
              />
            </svg>
            <div
              className="testimonial-one__thumb-pen wow fadeInUp"
              data-wow-delay="400ms"
            >
              <img
                src="assets/images/shapes/testimonial-shape-1.png"
                alt="eduact"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
