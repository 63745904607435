import React from "react";
import { Link } from "react-router-dom";

export const DiscountSection = () => {
  return (
    <section
      className="counter-one"
      style={{
        //backgroundImage: "url(assets/images/shapes/counter-bg-1-blue.jpg)",
        backgroundColor: "#0e0f85",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 wow fadeInLeft" data-wow-delay="200ms">
            <div className="counter-one__left">
              <h4 className="counter-one__left__title">
                Special Limited-Time Offer
              </h4>
              <div className="counter-one__left__content">
                We are celebrating the launch of our course by offering those
                who register a special discount. Don't miss this chance to
                reduce costs on your path to being a faster, smarter reader.
                Before it ends, enrol now to take advantage of this exclusive
                deal! <br />
                Spaces are filling up fast, so don’t miss your chance to become
                a more efficient and informed reader. Your journey to faster,
                smarter reading begins here!
              </div>
              <h5 style={{ color: "#FFF", marginBottom: 18 }}>
                This is your moment- grab it before it just slips away! Your
                skills are just a course away.
              </h5>
              <Link to="/checkout" className="eduact-btn eduact-btn-second">
                <span className="eduact-btn__curve" />
                Buy This Course Today
                <i className="icon-arrow" />
              </Link>
              <img src="assets/images/shapes/counter-dot.png" alt="eduact" />
            </div>
          </div>
          <div className="col-lg-6" style={{ paddingTop: 60 }}>
            <div
              className="counter-one__shapes wow fadeInUp"
              data-wow-delay="200ms"
            >
              <svg
                viewBox="0 0 581 596"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M161.37 12.301C221.003 -53.0048 563.794 156.411 579.671 299.209C595.548 442.007 237.88 668.171 135.305 571.868C46.2938 488.252 -0.524429 189.658 161.37 12.301Z"
                  fill="url(#paint0_linear_227_946)"
                />
                <path
                  d="M289.511 579.243C203.626 594.241 -34.778 302.771 4.28926 182.908C43.3565 63.0458 313.639 12.301 483.973 114.853C666.745 224.904 435.092 553.933 289.511 579.243Z"
                  fill="url(#paint1_linear_227_946)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_227_946"
                    x1="172.303"
                    y1="27.9012"
                    x2="521.418"
                    y2="508.929"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset={0} stopColor="#4F5DE4" stopOpacity={0} />
                    <stop
                      offset="0.269374"
                      stopColor="#9EA6F0"
                      stopOpacity="0.550859"
                    />
                    <stop offset={1} stopColor="white" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_227_946"
                    x1="123.876"
                    y1="84.092"
                    x2="408.261"
                    y2="553.853"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset={0} stopColor="#FF7200" />
                    <stop offset={1} stopColor="white" stopOpacity={0} />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div
              className="counter-one__area wow zoomIn"
              data-wow-delay="400ms"
            >
              <h5 className="counter-one__title" style={{ fontSize: 50 }}>
                Buy Now and
                <br /> Get a <span>50% Discount</span>
              </h5>
              <span
                style={{
                  fontSize: 25,
                  fontWeight: "bold",
                  color: "#00C000",
                }}
              >
                6,500 INR
              </span>
              <del
                style={{
                  marginLeft: 15,
                  fontSize: 25,
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                13,000
              </del>

              {/* /.countdown-one__list list-unstyled */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
